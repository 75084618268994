import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Checkbox,
  FormControl,
  IconButton,
  InputBase,
  InputLabel,
  LinearProgress,
  ListItemText,
  MenuItem,
  Pagination,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  styled,
} from '@mui/material';
import FuzzySearch from 'fuzzy-search';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import CandidateListEntry from '../components/CandidateListEntry';
import RecruitmentHeader from '../components/RecruitmentHeader';
import useAfasData from '../hooks/useAfasData';
import { uniqBy } from 'lodash';

function CandidatesList() {
  const { t, i18n } = useTranslation('common');

  const StyledTableCell = styled(TableCell)({
    paddingInline: '2em !important',
    paddingBlock: '1em',
  });

  const {
    data: candidatesData = [],
    isLoading: candidatesIsLoading = true,
  } = useAfasData(`/applicants/overview`, {
    lang: i18n.language
  });

  const {
    data: listData = [],
    isLoading: listLoading,
  } = useAfasData('/vacancies/list', {
    lang: i18n.language
  });

  const statusCodes = ['002', '003', '004', '005', '007'];
  const dateSorts = ['candidates.sort.dateasc', 'candidates.sort.datedesc'];
  const defaultVacancyFilter = {
    name: t('candidates.footer.all'),
    id: 0,
  };

  const [candidates, setCandidates] = useState([]);
  const [filteredCandidates, setFilteredCandidates] = useState(candidates);
  const [searchValue, setSearchValue] = useState('');
  const [statusFilters, setStatusFilters] = useState(statusCodes);
  const [vacancies, setVacancies] = useState([defaultVacancyFilter]);
  const [vacancyFilter, setVacancyFilter] = useState(0);
  const [dateFilter, setDateFilter] = useState(dateSorts[0]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const candidatesSearcher = new FuzzySearch(candidates, ['ApplicantName'], {
    caseSensitive: false,
    sort: true,
  });

  const getRandomDate = () => {
    const end = new Date();
    const start = new Date();
    start.setMonth(end.getMonth() - 2);
    const randomDate = new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
    return randomDate;
  };

  const byStatus = (candidates) => {
    return candidates.filter((candidate) => statusFilters.includes(candidate.StatusCode));
  };

  const byDate = (candidates) => {
    return candidates.sort((a, b) => {
      const dateA = new Date(a.Date);
      const dateB = new Date(b.Date);
      return dateFilter === 'candidates.sort.dateasc' ? dateB - dateA : dateA - dateB;
    });
  };

  const byVacancy = (candidates) => {
    return vacancyFilter === defaultVacancyFilter.id
      ? candidates
      : candidates.filter((candidate) => candidate.VacancyID === vacancyFilter);
  };

  const byPage = (candidates) => {
    const startIndex = (currentPage - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    return candidates.slice(startIndex, endIndex);
  };

  useEffect(() => {
    setCandidates(
      candidatesData.map((candidate) => {
        const mainVacancy = listData.find((vacancy) => vacancy.VacancyID === candidate.VacancyID) || {};
        if (mainVacancy) {
          return { ...candidate, Date: candidate.Date || getRandomDate(), ...mainVacancy };
        }
        return { ...candidate, Date: candidate.Date || getRandomDate() };
      })
    );
    setVacancies(
      uniqBy([defaultVacancyFilter].concat(
        candidatesData.map((candidate) => {
          return {
            id: candidate.VacancyID,
            name: candidate.VacancyTitle,
          };
        }
        )), 'id')
    );
  }, [candidatesData, listData]);

  useEffect(() => {
    let result = [...candidates];
    if (searchValue) {
      result = candidatesSearcher.search(searchValue);
    } else {
      result = byVacancy(result);
      result = byStatus(result);
      result = byDate(result);
    }
    setCurrentPage(1);
    setFilteredCandidates(result);
    setRowsPerPage(result.length > 10 ? 10 : result.length);
  }, [candidates, searchValue, statusFilters, dateFilter, vacancyFilter]);

  const paginatedCandidates = useMemo(
    () => byPage(filteredCandidates),
    [filteredCandidates, currentPage, rowsPerPage]
  );

  const handleStatusChange = (event) => setStatusFilters(event.target.value);
  const handleVacancyChange = (event) => setVacancyFilter(event.target.value);
  const handleDateChange = (event) => setDateFilter(event.target.value);
  const handleRowsChange = (event) => setRowsPerPage(event.target.value);
  const handlePageChange = (event, newPage) => setCurrentPage(newPage);

  return (
    <>
      <RecruitmentHeader numberVacancies={listData.length} />
      <Box sx={{ p: { md: 0 }, overflowX: 'auto' }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} useFlexGap sx={{ mb: 5 }}>
          <Stack my={1} direction="row" justifyContent="center" useFlexGap alignItems="center" spacing={6}>
            <Typography
              variant="h2"
              component="h2"
              sx={{
                fontSize: '20px !important',
                fontWeight: 700,
                letterSpacing: '0.7px',
              }}
            >
              {t('candidates.overview')}
            </Typography>
            <Box
              className="rounded-box"
              sx={(theme) => ({
                display: 'flex',
                flexWrap: 'wrap',
                bgcolor: theme.vars.palette.common.white,
                boxShadow: theme.shadows[1],
              })}
            >
              <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                <SearchIcon sx={{ stroke: 'black' }} />
              </IconButton>
              <InputBase
                sx={{
                  flex: 1,
                  pr: 2,
                  pl: 1,
                }}
                placeholder={t('candidates.search')}
                inputProps={{ 'aria-label': t('candidates.search') }}
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </Box>
          </Stack>
          <Stack my={1} useFlexGap direction="row" justifyContent="center" alignItems="center" spacing={4}>
            <FormControl
              variant="outlined"
              size="small"
            >
              <InputLabel id="select-status-label">{t('candidates.status.title')}</InputLabel>
              <Select
                labelId="select-status-label"
                id="select-status"
                multiple
                label={t('candidates.status.title')}
                value={statusFilters}
                displayEmpty
                IconComponent={KeyboardArrowDownRoundedIcon}
                renderValue={() => {
                  if (statusFilters.length === statusCodes.length) {
                    return t('candidates.status.all');
                  }
                  return t(`candidates.status.filter`)
                }}
                onChange={handleStatusChange}
              >
                {statusCodes.map((code) => {
                  return (
                    <MenuItem key={code} value={code}>
                      <Checkbox checked={statusFilters.indexOf(code) > -1} />
                      <ListItemText primary={t(`candidates.status.${code}`)} />
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            {listData && !listLoading && (
              <FormControl
                variant="outlined"
                size="small"
              >
                <InputLabel id="select-vacancy-label">{t('candidates.vacancy')}</InputLabel>
                <Select
                  labelId="select-vacancy-label"
                  id="select-vacancy"
                  value={vacancyFilter}
                  displayEmpty
                  label={t('candidates.vacancy')}
                  IconComponent={KeyboardArrowDownRoundedIcon}
                  onChange={handleVacancyChange}
                >
                  <MenuItem key={0} value={0}>
                    <ListItemText primary={t('candidates.header.vacancy')} />
                  </MenuItem>
                  {listData.filter(vacancy => candidates.find(candidate => candidate.VacancyID === vacancy.VacancyID)).map((vacancy) => {
                    return (
                      <MenuItem key={vacancy.VacancyID} value={vacancy.VacancyID}>
                        <ListItemText primary={vacancy.VacancyTitle} />
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            )}
            <FormControl
              variant="outlined"
              size="small"
            >
              <InputLabel id="sort-date-label">{t('candidates.date')}</InputLabel>
              <Select
                labelId="sort-date-label"
                id="sort-date"
                value={dateFilter}
                label={t('candidates.date')}
                IconComponent={KeyboardArrowDownRoundedIcon}
                onChange={handleDateChange}
              >
                {dateSorts.map((sort) => {
                  return (
                    <MenuItem key={sort} value={sort}>
                      <ListItemText primary={t(sort)} />
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <Typography variant="body2" component="p" fontSize="16px" letterSpacing="0.56px">
              {currentPage} - {paginatedCandidates.length} {t('candidates.header.of')}{' '}
              {filteredCandidates.length} {t('candidates.header.candidates')}
            </Typography>
          </Stack>
        </Stack>
        {candidatesIsLoading && <LinearProgress mt={2} />}
        {!candidatesIsLoading && filteredCandidates.length > 0 && (
          <>
            <Box sx={{ mt: 0, mb: 2 }}>
              <Table sx={{ borderSpacing: '0 0.75em !important', borderCollapse: 'separate !important' }}>
                <TableHead>
                  <TableRow style={{ background: 'white' }}>
                    <StyledTableCell>
                      <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                        {t('candidates.header.name')}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                        {t('candidates.header.rating')}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                        {t('candidates.header.vacancy')}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                        {t('candidates.header.status')}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                        {t('candidates.header.averagescore')}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                        {t('candidates.header.applicantstam')}
                      </Typography>
                    </StyledTableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {paginatedCandidates.length > 0 &&
                    paginatedCandidates.map((candidate) => {
                      return <CandidateListEntry
                        key={candidate.SubjectID}
                        candidate={candidate}
                        view={'candidates-list'}
                      />;
                    })}
                </TableBody>
              </Table>
            </Box>
            <Stack
              direction="row"
              alignItems="center"
              spacing={3}
              justifyContent="flex-end"
              sx={{ px: '2em', mt: 2, mb: 3 }}
            >
              <Pagination
                count={filteredCandidates <= 0 ? 0 : Math.ceil(filteredCandidates.length / rowsPerPage)}
                page={currentPage}
                onChange={handlePageChange}
              />
              <FormControl
                size="small"
                variant="outlined"
                sx={{
                  minWidth: '200px',
                }}
              >
                <InputLabel id="demo-simple-select-standard-label">{t('candidates.footer.rowsperpage')}</InputLabel>
                <Select
                  MenuProps={{ autoFocus: false, disableAutoFocus: true }}
                  labelId="rows-select-label"
                  id="rows-select"
                  value={rowsPerPage}
                  onChange={handleRowsChange}
                  label={t('candidates.footer.rowsperpage')}
                  displayEmpty
                  className="filter-select"
                  IconComponent={KeyboardArrowDownRoundedIcon}
                >
                  {[filteredCandidates.length, 5, 10, 25, 50, 100].map((amountOfRows, i) => {
                    return i === 0 && amountOfRows <= 100 ? (
                      <MenuItem key={i} value={amountOfRows}>
                        <ListItemText primary={amountOfRows} secondary={`(${defaultVacancyFilter})`} />
                      </MenuItem>
                    ) : (
                      amountOfRows < filteredCandidates.length && (
                        <MenuItem key={i} value={amountOfRows}>
                          <ListItemText primary={amountOfRows} />
                        </MenuItem>
                      )
                    );
                  })}
                </Select>
              </FormControl>
            </Stack>
          </>
        )}
        {!candidatesIsLoading && filteredCandidates.length === 0 && (
          <Typography align="center" variant="h6">
            {t('candidates.list.noresults')}
          </Typography>
        )}
      </Box>
    </>
  );
}

export default CandidatesList;
