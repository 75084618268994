import { Backdrop, Box, List, ListItem, Popover, Rating, Typography } from '@mui/material';
import styled from '@emotion/styled';

const BackdropBox = styled(Backdrop)({
  backgroundColor: 'transparent',
});

const PopupCompetenceRating = ({ label, rating }) => {
  return (
    <ListItem
      sx={{ paddingTop: 0, paddingLeft: 0, paddingRight: 0, justifyContent: 'space-between' }}
      alignItems="center"
    >
      <Typography
        variant="body1"
        sx={{
          marginRight: '1rem',
        }}
      >
        {label}
      </Typography>
      <Box
        sx={(theme) => ({
          display: 'inline-block',
          bgcolor: theme.vars.palette.grey.A100,
          borderRadius: '2em',
          padding: '.25em 1em .25em 1em',
        })}
      >
        <Rating max={5} readOnly value={rating} sx={{ fontSize: '1.2rem', display: 'flex' }} />
      </Box>
    </ListItem>
  );
};

const CompetencePopover = ({ applicationData, member, anchorEl, open, onClose }) => {
  return (
    <Popover
      id={applicationData.SubjectID}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      slots={{ backdrop: BackdropBox }}
      disableAutoFocus={true}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      sx={(theme) => ({
        '& .MuiPopover-paper': {
          backgroundColor: '#FFF',
          borderRadius: '1rem',
          // boxShadow: '0px 15px 15px rgba(0,0,0,0.08) !important',
          boxShadow: theme.shadows[1]
        },
      })}
    >
      <Box px={4} pt={3} pb={2}>
        <List
          pt={1}
          sx={{
            padding: 0,
          }}
        >
          {applicationData.Competence1 && member.Competence1 && (
            <PopupCompetenceRating label={applicationData.Competence1} rating={member.Competence1} />
          )}
          {applicationData.Competence2 && member.Competence2 && (
            <PopupCompetenceRating label={applicationData.Competence2} rating={member.Competence2} />
          )}
          {applicationData.Competence3 && member.Competence3 && (
            <PopupCompetenceRating label={applicationData.Competence3} rating={member.Competence3} />
          )}
          {applicationData.Competence4 && member.Competence4 && (
            <PopupCompetenceRating label={applicationData.Competence4} rating={member.Competence4} />
          )}
          {applicationData.Competence5 && member.Competence5 && (
            <PopupCompetenceRating label={applicationData.Competence5} rating={member.Competence5} />
          )}
        </List>
      </Box>
    </Popover>
  );
};

export default CompetencePopover;
