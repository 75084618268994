import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import EditIcon from '@mui/icons-material/Edit';
import {
  Avatar,
  Box,
  Button,
  IconButton,
  styled,
  TableCell,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material';
import { Stack } from '@mui/system';
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/nl';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { InSiteContext } from '../context/InSiteContext';
import { DashboardIcon, TaskListIcon } from './Icons';

const StyledCell = styled(TableCell)({
  border: 'none',
  padding: 0,
  paddingInline: 8,
});

const VacancyListEntry = ({ vacancy, count }) => {
  const navigate = useNavigate();
  dayjs.locale('nl-NL');
  const shortEndDate = dayjs(vacancy.EndDate).format('D MMM').toLowerCase();
  const insiteUrl = useContext(InSiteContext);
  const { t } = useTranslation('common');
  const location = useLocation();

  const VacancyBox = ({ title, icon, text, bottomText, link, linkTooltip, blank, custom }) => {
    return (
      <Box
        alignItems="center"
        justifyContent="center"
        sx={(theme) => ({
          bgColor: theme.vars.palette.background.default,
          boxShadow: theme.shadows[1],
          borderRadius: '9px',
          height: '100%',
          maxHeight: 171,
          width: 'fit-content',
          minWidth: 122,
          position: 'relative',
          px: 2,
        })}
      >
        <Stack direction="column" spacing={2} alignItems="center" justifyContent="center" height="100%">
          <Typography
            variant="body1"
            fontSize="15px"
            letterSpacing="0.525px"
            sx={{ textWrap: 'nowrap !important' }}
          >
            {title}
          </Typography>
          <Box
            sx={(theme) => ({
              alignItems: 'center',
              justifyContent: 'center',
              width: '4.25rem',
              height: '4.25rem',
            })}
          >
            {custom && custom}
            {!custom && (
              <>
                {icon && !text && (
                  <Avatar
                    alt={`${title} icon`}
                    sx={(theme) => ({
                      width: '70%',
                      height: 'auto',
                      margin: '5px auto auto auto',
                      bgcolor: 'transparent',
                      borderRadius: '0',
                      '& svg #Vector, & svg #tasks_2 path': {
                        fill: theme.palette.grey[300],
                      },
                      '& svg #Vector_2, & svg #tasks path': {
                        fill: theme.vars.palette.primary.main,
                      },
                    })}
                  >
                    {icon}
                  </Avatar>
                )}

                {!icon && text && (
                  <Stack
                    sx={{ background: '#E5E5E5', borderRadius: '8px', p: 1 }}
                    height="100%"
                    width="100%"
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography
                      variant="body1"
                      fontWeight="600"
                      fontSize="21px"
                      textAlign="center"
                      sx={{ textWrap: 'balance' }}
                    >
                      {text}
                    </Typography>
                    {bottomText && (
                      <Typography
                        variant="body2"
                        fontWeight="500"
                        fontSize="16px"
                        whiteSpace="nowrap"
                        textOverflow="hidden"
                      >
                        {bottomText}
                      </Typography>
                    )}
                  </Stack>
                )}
              </>
            )}
          </Box>
        </Stack>
        {link && (
          <Box position="absolute" sx={{ right: 0, bottom: 0 }}>
            <Tooltip arrow title={linkTooltip ?? ''}>
              <div>
                {blank && (
                  <IconButton href={link} target="_blank" className="ev_view-vacancy-progress">
                    <ArrowForwardIosIcon
                      style={{ fontSize: '0.7rem', fontWeight: '900', lineHeight: 'normal' }}
                    />
                  </IconButton>
                )}
                {!blank && (
                  <IconButton
                    onClick={() => {
                      navigate({
                        pathname: link,
                        search: location.search,
                      });
                    }}
                    className="ev_view-vacancy-progress"
                  >
                    <ArrowForwardIosIcon
                      style={{ fontSize: '0.7rem', fontWeight: '900', lineHeight: 'normal' }}
                    />
                  </IconButton>
                )}
              </div>
            </Tooltip>
          </Box>
        )}
      </Box>
    );
  };

  return (
    <>
      <TableRow>
        <StyledCell width="50%">
          <Stack
            direction="column"
            alignItems="start"
            justifyContent="space-between"
            height="85%"
            width="100%"
          >
            <Typography
              variant="h2"
              sx={(theme) => ({
                fontSize: '20px !important',
                fontWeight: 600,
                letterSpacing: '0.7px',
                flex: 1,
                color: `${vacancy.Enriched ? 'inherit' : theme.vars.palette.error.main} !important`,
              })}
            >
              {vacancy.VacancyTitle}
            </Typography>
            <Stack direction="row" spacing={3} alignItems="flex-end" justifyContent="left" width="100%">
              <Typography
                variant="body1"
                component="span"
                sx={(theme) => ({
                  color: `${theme.vars.palette.primary.main} !important`,
                  display: 'inline-block',
                  fontWeight: 500,
                  fontSize: '16px',
                  textWrap: 'nowrap',
                  letterSpacing: '0.56px',
                })}
              >
                ID: {vacancy?.VacancyID}
              </Typography>
              <Box display="flex" flexWrap="nowrap" alignItems="flex-end">
                <Typography
                  variant="body1"
                  sx={{ fontSize: '14px', fontWeight: 500, letterSpacing: '0.49px' }}
                >
                  {t('vacancies.header.chairperson')}:&nbsp;
                </Typography>
                <Typography
                  variant="body1"
                  noWrap
                  sx={{ fontSize: '14px', fontWeight: 400, letterSpacing: '0.49px' }}
                >
                  {vacancy?.ChairPerson}
                </Typography>
              </Box>
            </Stack>
          </Stack>
        </StyledCell>
        <StyledCell>
          <VacancyBox
            title={t('vacancies.list.end')}
            text={vacancy.EndDate ? shortEndDate.split(' ')[0] : '?'}
            bottomText={vacancy.EndDate ? shortEndDate.split(' ')[1] : undefined}
          />
        </StyledCell>
        <StyledCell>
          <VacancyBox title={t('vacancies.list.candidates')} text={`${vacancy.AmountCandidates ?? '?'}`} />
        </StyledCell>
        <StyledCell>
          <VacancyBox
            title={t('vacancies.list.myprogress')}
            custom={
              <Stack
                sx={{ background: '#E5E5E5', borderRadius: '8px', p: 1 }}
                height="100%"
                width="100%"
                direction="column"
                alignItems="center"
                justifyContent="center"
                spacing={0}
              >
                <Typography
                  variant="body1"
                  fontWeight="600"
                  fontSize="21px"
                  textAlign="center"
                  lineHeight={1}
                  sx={{ textWrap: 'balance' }}
                >
                  {count}
                </Typography>
                <Typography
                  variant="body2"
                  fontWeight="500"
                  fontSize="15px"
                  whiteSpace="nowrap"
                  textOverflow="hidden"
                  color="#B6B6B6"
                  lineHeight={1}
                >
                  / {vacancy.AmountCandidates}
                </Typography>
              </Stack>
            }
          />
        </StyledCell>
        <StyledCell>
          <VacancyBox
            title={t('vacancies.list.progress')}
            icon={<TaskListIcon width="45" height="45" />}
            link={`/vacancies/${vacancy.VacancyID}/evaluation-progress/`}
            linkTooltip={t('vacancies.list.viewprogress')}
          />
        </StyledCell>
        {/* <StyledCell>
          <VacancyBox
            title="Dashboard"
            icon={<DashboardIcon width="45" height="45" />}
            link="https://lookerstudio.google.com/reporting/8fc12f1a-6556-4423-908a-d11edab4a79e"
            blank={true}
          />
        </StyledCell> */}

        <StyledCell align="right" width="10%">
          <Stack direction="column" alignItems="flex-end" justifyContent="space-between" height="85%">
            <Tooltip arrow placement="bottom-start" title={t('vacancies.list.edit')}>
              <IconButton
                className="ev_view-vacancy-details"
                id={`edit-details-${vacancy.VacancyID}`}
                sx={{
                  paddingTop: 0,
                  paddingBottom: 0,
                  '&:hover': {
                    backgroundColor: 'transparent',
                  },
                  '&.MuiIconButton-root': { pr: 0 },
                }}
                href={`${insiteUrl}/vacature-prs?VcSn=${vacancy.VacancyID}`}
                target="_blank"
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  spacing={1}
                  alignItems="center"
                  width="100%"
                >
                  <EditIcon style={{ fontSize: '16px', opacity: '0.7', fontWeight: 900, color: '#474747' }} />
                  <Typography
                    variant="body1"
                    sx={{
                      opacity: '0.7',
                      fontWeight: '400',
                      fontSize: '16px',
                      letterSpacing: '0.56px',
                      cursor: 'pointer',
                      textAlign: 'end',
                      color: '#474747',
                    }}
                  >
                    {t('vacancies.list.editshort')}
                  </Typography>
                </Stack>
              </IconButton>
            </Tooltip>

            <Link
              state={{
                data: {
                  title: vacancy.VacancyTitle,
                  candidates: vacancy.AmountCandidates,
                },
              }}
              className="ano-link i-block"
              to={`/vacancies/${vacancy.VacancyID}/applications/cards/${location.search}`}
            >
              <Button
                className="ev_view-vacancy-applications-swipe vacancy-list-entry-button"
                variant="contained"
                sx={{ minWidth: '130px' }}
              >
                {count === '-' && t('vacancies.list.view')}
                {count === 0 && vacancy.AmountCandidates !== 0 && t('vacancies.list.evaluate')}
                {count > 0 && count < vacancy.AmountCandidates && t('vacancies.list.continue')}
                {count === vacancy.AmountCandidates && t('vacancies.list.view')}
              </Button>
            </Link>
          </Stack>
        </StyledCell>
      </TableRow>
    </>
  );
};

export default VacancyListEntry;
