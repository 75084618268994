import { Box, Button, Fab, FormControl, IconButton, InputLabel, MenuItem, Popover, Select, Stack, TextField, Typography, Tooltip, Divider, Badge } from "@mui/material";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import TuneIcon from '@mui/icons-material/Tune';
import FuzzySearch from "fuzzy-search";
import _orderBy from 'lodash/orderBy';

const ApplicationsFilter = ({
  vacancyData,
  applicationData,
  applications,
  setApplications,
  setFilters,
  filters
}) => {
  const { t } = useTranslation('common');

  const mainOperators = [
    ["meetall", t('candidates.list.filter.meetall')],
    ["meetone", t('candidates.list.filter.meetone')]
  ];

  const allowedFilters = [
    {
      "field": "ApplicantName",
      "label": t('fields.ApplicantName'),
      "type": "text",
      "operators": [
        "equal",
        "unequal",
        "contains",
        "startswith",
        "containsnot",
      ]
    },
    {
      "field": "Age",
      "label": t('fields.Age'),
      "type": "number",
      "operators": [
        "equal",
        "unequal",
        "greaterthan",
        "lessthan",
      ]
    },
    {
      "field": "Nationality",
      "label": t('fields.Nationality'),
      "type": "text",
      "operators": [
        "equal",
        "unequal",
        "contains",
        "startswith",
        "containsnot",
      ]
    },
    {
      "field": "Gender",
      "label": t('fields.Gender'),
      "type": "text",
      "operators": [
        "equal",
        "unequal",
      ]
    },
    {
      "field": "City",
      "label": t('fields.City'),
      "type": "text",
      "operators": [
        "equal",
        "unequal",
        "contains",
        "startswith",
        "containsnot",
      ]
    },
    {
      "field": "Country",
      "label": t('fields.Country'),
      "type": "text",
      "operators": [
        "equal",
        "unequal",
        "contains",
        "startswith",
        "containsnot",
      ]
    },
    {
      "field": "Email",
      "label": t('fields.Email'),
      "type": "text",
      "operators": [
        "equal",
        "unequal",
        "contains",
        "startswith",
        "containsnot",
      ]
    },
    {
      "field": "Phone",
      "label": t('fields.Phone'),
      "type": "text",
      "operators": [
        "equal",
        "unequal",
        "contains",
        "startswith",
        "containsnot",
      ]
    },
    {
      "field": "StatusCode",
      "label": t('fields.Status'),
      "type": "text",
      "operators": [
        "equal",
        "unequal",
      ],
      // "possibleValues": [
      //   "002",
      //   "003",
      //   "004",
      //   "005",
      //   "007",
      // ]
    },
  ];

  const operators = [
    {
      "operator": "equal",
      "label": t('candidates.list.filter.equal'),
      "method": (a, b) => { // a is the value of the applicant, b is the value to compare
        if (a === null) return false;
        if (typeof a === 'string' || a instanceof String) return a.toLowerCase() === b.toLowerCase();
        return a === b;
      }
    },
    {
      "operator": "unequal",
      "label": t('candidates.list.filter.unequal'),
      "method": (a, b) => {
        if (a === null) return true;
        if (typeof a === 'string' || a instanceof String) return a.toLowerCase() !== b.toLowerCase();
        return a !== b;
      }
    },
    {
      "operator": "contains",
      "label": t('candidates.list.filter.contains'),
      "method": (a, b) => {
        if (a === null) return false;
        if (typeof a === 'string' || a instanceof String) {
          a = a.toLowerCase();
          b = b.toLowerCase();
        }
        return a.includes(b);
      }
    },
    {
      "operator": "startswith",
      "label": t('candidates.list.filter.startswith'),
      "method": (a, b) => {
        if (a === null) return false;
        if (typeof a === 'string' || a instanceof String) {
          a = a.toLowerCase();
          b = b.toLowerCase();
        }
        return a.startsWith(b);
      }
    },
    {
      "operator": "containsnot",
      "label": t('candidates.list.filter.containsnot'),
      "method": (a, b) => {
        if (a === null) return false; // could be true
        if (typeof b === 'string' || b instanceof String) {
          a = a.toLowerCase();
          b = b.toLowerCase();
        }
        return !a.includes(b);
      }
    },
    {
      "operator": "greaterthan",
      "label": t('candidates.list.filter.greaterthan'),
      "method": (a, b) => a > b
    },
    {
      "operator": "lessthan",
      "label": t('candidates.list.filter.lessthan'),
      "method": (a, b) => a < b
    }
  ];


  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const [mainOperator, setMainOperator] = useState("meetall");

  const handleAddFilter = () => {
    const updatedFilters = { ...filters };
    updatedFilters.filters.push({ field: "", filter: {}, operator: "", value: "", possibleValues: [] })
    setFilters(() => updatedFilters);
  };

  const handleDeleteFilter = (index) => {
    const updatedFilters =
    {
      ...filters,
      filters: filters.filters.filter((_, i) => i !== index)
    }
    setFilters(updatedFilters);
  };

  const handleChange = (index, key, value) => {
    const updatedFilters = { ...filters };
    updatedFilters.filters[index][key] = value;
    // Set possible values
    updatedFilters.filters.map((filter) => {
      // if (filter.field === 'StatusCode' || (key === 'field' && value === 'StatusCode')) return filter;
      if (["equal", "unequal"].includes(filter.operator.operator)) {
        filter.possibleValues = [...new Set(applicationData.map((app) => app[filter.field]))].filter((value) => value !== null).sort();
      } else {
        filter.possibleValues = [];
      }
      return filter;
    });
    setFilters(updatedFilters);
  };

  const handleFilterData = () => {
    // Filter out all filters
    setAnchorEl(null);
    setFilters(filters => filters);
  };

  useEffect(() => {
    setApplications(applicationData)
  }, [applicationData, setApplications]);

  useEffect(() => {
    if (applicationData?.length < 1) return setApplications([]);
    let result = [...applicationData];
    if (filters?.status?.value) {
      result = filters.status.value === '002'
        ? result?.filter((app) => app.ReviewedByUser === false)
        : result?.filter((app) => filters.status.value?.split(',').includes(app[filters.status.field]));
    }
    if (filters.search?.value !== "") {
      const applicationSearcher = new FuzzySearch(result, ['ApplicantName'], {
        caseSensitive: false,
        sort: false,
      });
      result = applicationSearcher.search(filters.search.value);
    }
    const filtersToFilter = filters.filters.filter((filter) => filter.value !== "" && filter.operator !== "");
    // Filter logic can be implemented here based on filters array
    if (filtersToFilter.length > 0) {
      switch (mainOperator) {
        case "meetall":
          result = result.filter((app) => filtersToFilter.every((filter) => filter.operator.method(app[filter.field], filter.value)));
          break;
        case "meetone":
          result = result.filter((app) => filtersToFilter.some((filter) => filter.operator.method(app[filter.field], filter.value)));
          break;
        default:
          break;
      }
    }
    result = _orderBy(
      result,
      Array.isArray(filters.sort.field) ? filters.sort.field : [filters.sort.field],
      Array.isArray(filters.sort.order) ? filters.sort.order : [filters.sort.order]);
    setApplications(result);
    // console.log(filtersToFilter, result, filters);
  }, [filters, applicationData, mainOperator, setApplications]);

  return (
    <>
      <Badge badgeContent={filters.filters.length} color="secondary">
        <Button onClick={handleClick} endIcon={<FilterAltIcon />} variant="text">{t('vacancies.list.addfilter')}</Button>
      </Badge>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        disableAutoFocus={true}
        slotProps={{
          paper: {
            sx: (theme) => ({
              minWidth: '900px',
              maxWidth: '80vw',
              boxShadow: theme.shadows[1],
              borderRadius: '8px',
            })
          }
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box sx={{ py: 3, px: 6 }}>
          <Stack direction="row" alignItems={"center"} justifyContent="space-between" mb={4}>
            <Typography variant="h4">{t('candidates.list.filter.add')}</Typography>
            <Fab onClick={handleClose} size="small" sx={(theme) => ({ bgcolor: 'white', boxShadow: theme.shadows[1] })} color="light" aria-label="add">
              <CloseIcon className="reject-color-fill" />
            </Fab>
          </Stack>
          <Stack gap={4} direction="row" alignItems={"center"} justifyContent="flex-start">
            <Box sx={{ flex: '1 1 15%' }}>
              <Typography variant="body1" color="initial">{t('candidates.list.filter.rule')}</Typography>
            </Box>
            <Box sx={{ flex: '1 1 85%' }}>
              <Stack direction="row" gap={2} alignItems={"center"}>
                <FormControl>
                  <Select
                    MenuProps={{ autoFocus: false, disableAutoFocus: true }}
                    value={mainOperator}
                    onChange={(e) =>
                      setMainOperator(e.target.value)
                    }
                  >
                    {mainOperators.map(([key, label]) =>
                    (
                      <MenuItem key={key} value={key}>
                        {label}
                      </MenuItem>
                    )
                    )}
                  </Select>
                </FormControl>
                <Tooltip title={t('candidates.list.filter.add')}>
                  <IconButton variant="contained" onClick={handleAddFilter}>
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              </Stack>
            </Box>
          </Stack>
          <Box my={4}>
            <Divider />
          </Box>
          <Box>
            {filters.filters.map((filter, index) => (
              <Stack key={index} gap={4} direction="row" alignItems={"center"} justifyContent="flex-start" mb={2}>
                <Box sx={{ flex: '1 1 15%' }}>
                  <Typography variant="body1" color="initial">Filter {index + 1}</Typography>
                </Box>
                <Stack gap={2} direction={"row"} sx={{ flex: '1 1 85%' }}>
                  <FormControl fullWidth>
                    <InputLabel sx={{ background: 'white' }}>{t('candidates.list.filter.filteron')}</InputLabel>
                    <Select
                      MenuProps={{ autoFocus: false, disableAutoFocus: true }}
                      value={filter.field || ""}
                      label={t('candidates.list.filter.filteron')}
                      onChange={(e) => {
                        handleChange(index, "field", e.target.value);
                        handleChange(index, "filter", allowedFilters.find((f) => f.field === e.target.value) || e.target.value);
                      }}
                    >
                      {allowedFilters.map((filter) => (
                        <MenuItem key={filter.field} value={filter.field}>
                          {filter.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl fullWidth>
                    <InputLabel sx={{ background: 'white' }}>{t('candidates.list.filter.operator')}</InputLabel>
                    <Select
                      MenuProps={{ autoFocus: false, disableAutoFocus: true }}
                      value={filter.operator.operator || ""}
                      label={t('candidates.list.filter.operator')}
                      onChange={(e) =>
                        handleChange(index, "operator", operators.find((op) => op.operator === e.target.value))
                      }
                    >
                      {(filters.filters.length > 0 && filter.filter?.operators) ? (
                        filter.filter.operators.map(operator => (
                          <MenuItem key={operator} value={operator}>
                            {operators.find((op) => op.operator === operator).label}
                          </MenuItem>
                        ))) : (
                        operators.map((operator) => (
                          <MenuItem key={operator.operator} value={operator.operator}>
                            {operator.label}
                          </MenuItem>
                        ))
                      )}
                    </Select>
                  </FormControl>

                  {(["equal", "unequal"].includes(filter.operator.operator)) ? (
                    <FormControl fullWidth>
                      <InputLabel sx={{ background: 'white' }}>{t('candidates.list.filter.value')}</InputLabel>
                      <Select
                        MenuProps={{ autoFocus: false, disableAutoFocus: true }}
                        value={filter.value || ""}
                        label={t('candidates.list.filter.value')}
                        onChange={(e) =>
                          handleChange(index, "value", e.target.value)
                        }
                      >
                        {filter.possibleValues.map((val) => (
                          <MenuItem key={val} value={val}>
                            {filter.filter.field === 'StatusCode' ? t(`candidates.status.${val}`) : val}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  ) : (
                    <TextField
                      fullWidth
                      type={filter?.filter?.type || "text"}
                      label={t('candidates.list.filter.value')}
                      value={filter.value || ""}
                      onChange={(e) =>
                        handleChange(index, "value", e.target.value)
                      }
                    />
                  )}
                  <Tooltip title={t('candidates.list.filter.removerule')}>
                    <IconButton
                      aria-label="delete"
                      onClick={() => handleDeleteFilter(index)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </Stack>
            ))}
          </Box>
          <Box mt={4}>
            <Stack direction="row" justifyContent="flex-end">
              <Button variant="contained" color="primary" endIcon={<TuneIcon />} onClick={() => handleFilterData()}>{t('candidates.list.filter.save')}</Button>
            </Stack>
          </Box>
        </Box>
      </Popover>
    </>
  );
}

export default ApplicationsFilter;