import { LinearProgress, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Outlet, useParams } from 'react-router-dom';
import CardsHeader from '../components/CardsHeader';
import SwiperCard from '../components/SwiperCard';
import SwiperCardsArrows from '../components/SwiperCardsArrows';
import useAfasData from '../hooks/useAfasData';
import { useLocation } from 'react-router-dom';
import VacancyNavigation from '../components/VacancyNavigation';

function VacancyCards() {
  const params = useParams();
  const carouselRef = useRef();
  const vacancyId = params.VacancyID;

  const { t, i18n } = useTranslation('common');
  const location = useLocation();
  const slideIndex = location?.state?.slideIndex || 1;
  let carouselIndex = 0;

  const {
    data: applicationData = [],
    isLoading: applicationIsLoading,
    mutate: applicationMutator,
  } = useAfasData(`/vacancy/${vacancyId}/applications/open`, {
    lang: i18n.language
  });

  const {
    data: vacancyData = {},
    isLoading: vacancyIsLoading,
    mutate: infoMutator,
  } = useAfasData(`/vacancies/${vacancyId}/info`, {
    lang: i18n.language
  });

  useEffect(() => {
    carouselRef?.current?.goToSlide(1);
  }, [carouselRef]);

  useEffect(() => {
    if (slideIndex) {
      if (slideIndex === carouselRef?.current?.state?.totalItems) {
        carouselRef?.current?.goToSlide(slideIndex - 1);
      } else {
        carouselRef?.current?.goToSlide(slideIndex);
      }
    }
  }, [carouselRef, applicationData, slideIndex]);

  const competences = [];
  if (vacancyData) {
    if (vacancyData.Competence1 !== '') {
      competences.push(vacancyData.Competence1);
    }
    if (vacancyData.Competence2 !== '') {
      competences.push(vacancyData.Competence2);
    }
    if (vacancyData.Competence3 !== '') {
      competences.push(vacancyData.Competence3);
    }
    if (vacancyData.Competence4 !== '') {
      competences.push(vacancyData.Competence4);
    }
    if (vacancyData.Competence5 !== '') {
      competences.push(vacancyData.Competence5);
    }
  }

  return (
    <>
      <CardsHeader vacancyData={vacancyData} vacancyIsLoading={vacancyIsLoading} />
      <VacancyNavigation />
      <Box pb={5} mt={5}>
        {applicationIsLoading && (
          <Box mt={2}>
            <LinearProgress />
          </Box>
        )}
        {vacancyData && applicationData.length > 0 && !applicationIsLoading && (
          <Carousel
            customButtonGroup={<SwiperCardsArrows applications={applicationData} />}
            ref={carouselRef}
            arrows={false}
            responsive={{
              desktop: {
                breakpoint: { max: 3000, min: 1024 },
                items: 1,
                partialVisibilityGutter: 40,
              },
              mobile: {
                breakpoint: { max: 564, min: 0 },
                items: 1,
                partialVisibilityGutter: 40,
              },
            }}
            slidesToSlide={1}
            minimumTouchDrag={40}
            transitionDuration={500}
            centerMode={true}
            infinite={false}
            keyBoardControl
            swipeable
          >
            <Box
              key={0}
              sx={{
                visibility: 'hidden',
                pointerEvents: 'none',
              }}
            >
              <SwiperCard
                application={{
                  VacancyID: 0,
                  ApplicationID: 0,
                  SubjectID: 0,
                  ApplicantID: '',
                  ApplicantName: '',
                  Age: 0,
                  NationalityCode: 0,
                  Nationality: 0,
                  Gender: 0,
                  StatusCode: 0,
                  StatusDescription: 0,
                  Rating: 0,
                }}
              />
            </Box>
            {applicationData.map((application) => {
              carouselIndex++;
              return (
                <Box key={application.ApplicationID}>
                  <SwiperCard
                    application={application}
                    role={vacancyData?.Role || undefined}
                    competences={competences}
                    applicationMutator={applicationMutator}
                    infoMutator={infoMutator}
                    view="swipe"
                    carouselIndex={carouselIndex}
                  />
                </Box>
              );
            })}
          </Carousel>
        )}
        {!applicationIsLoading && applicationData.length === 0 && (
          <>
            <Typography variant="h4" align="center" color="initial">
              {t('cards.card.none')}
            </Typography>
            <Typography variant="body1" color="initial" align="center">
              {t('cards.card.tooverview')}
            </Typography>
          </>
        )}
      </Box>
      <Outlet context={{ carouselRef: carouselRef }} />
    </>
  );
}

export default VacancyCards;
