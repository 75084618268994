import { Fab, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CheckIcon, OnHoldIcon, XIcon } from '../Icons';

const CommentRating = ({ rating }) => {
  const { t } = useTranslation('common');
  switch (rating) {
    case 3:
      return (
        <>
          <Tooltip arrow title={t('cards.card.inviteadvice')}>
            <Fab
              sx={{
                width: '50px',
                height: '50px',
                boxShadow: '0px 15px 15px rgba(0,0,0,0.08) !important',
                backgroundColor: '#1DC124',
                '&:hover': {
                  bgcolor: '#1DC124',
                },
              }}
            >
              <CheckIcon stroke="#FFF" width={24} height={24} />
            </Fab>
          </Tooltip>
        </>
      );
    case 2:
      return (
        <>
          <Tooltip arrow title={t('cards.card.onholdadvice')}>
            <Fab
              sx={{
                width: '50px',
                height: '50px',
                boxShadow: '0px 15px 15px rgba(0,0,0,0.08) !important',
                backgroundColor: '#319DD3',
                '&:hover': {
                  bgcolor: '#319DD3',
                },
              }}
            >
              <OnHoldIcon stroke="#FFF" fill="#FFF" width={24} height={24} style={{ marginLeft: '2px' }} />
            </Fab>
          </Tooltip>
        </>
      );
    case 1:
      return (
        <>
          <Tooltip arrow title={t('cards.card.rejectadvice')}>
            <Fab
              sx={{
                width: '50px',
                height: '50px',
                boxShadow: '0px 15px 15px rgba(0,0,0,0.08) !important',
                backgroundColor: '#EA0D0D',
                '&:hover': {
                  bgcolor: '#EA0D0D',
                },
              }}
            >
              <XIcon stroke="#FFF" width={24} height={24} />
            </Fab>
          </Tooltip>
        </>
      );
    default:
      return <></>;
  }
};

export default CommentRating;
