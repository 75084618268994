import {
  Box,
  Dialog,
  DialogContent,
  Fab,
  List,
  ListItem,
  Skeleton,
  Stack,
  styled,
  Typography,
  Tooltip,
  Backdrop,
  IconButton,
} from '@mui/material';
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/nl';
import { useContext, useEffect, useState } from 'react';
import _findIndex from 'lodash/findIndex';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import CardReviewSection from '../components/dialog/CardReviewSection';
import useAfasData from '../hooks/useAfasData';
import useWords from '../hooks/useWords';
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';
import DetailDialogHeader from '../components/dialog/DetailDialogHeader';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import DetailDialogSummary from '../components/dialog/DetailDialogSummary';
import CardListItemPersonal from '../components/dialog/CardListItemPersonal';
import { XIcon } from '../components/Icons';
import { BackIconButton } from '../components/BackToOverview';
import { TokenContext } from '../context/TokenContext';
import { InSiteContext } from '../context/InSiteContext';

const ApplicationDetailBackDrop = styled(Backdrop)({
  backgroundColor: 'rgba(255,255,255,0.38)',
  boxShadow: 'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px',
  marginTop: '2rem',
  marginLeft: '3%',
  marginRight: '3%',
  marginBottom: '3rem',
});

export const DetailDialogSectionTitle = styled(Typography)({
  fontWeight: '700',
  fontSize: '1.6rem !important',
  marginBottom: '1em !important',
  position: 'relative',
});

const SwiperIconButton = styled(IconButton)({
  borderRadius: '.9rem',
  backgroundColor: '#FFF',
  boxShadow:
    '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
  '&:hover': {
    backgroundColor: '#FFF',
  },
});

function VacancyDetailDialog() {
  // Force update
  // Get some basic information
  let params = useParams();
  const applicationId = Number(params.ApplicationID);
  const vacancyId = params.VacancyID;
  let navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation('common');

  const token = useContext(TokenContext);
  const insiteUrl = useContext(InSiteContext);

  const context = useOutletContext();
  const [currentIndex, setCurrentIndex] = useState(false);
  const [maxIndex, setMaxIndex] = useState(false);

  useEffect(() => {
    if (context === undefined || context?.hasOwnProperty('carouselRef')) return;
    function navigateTo(applicationId) {
      navigate({
        pathname: `/vacancies/${vacancyId}/applications/overview/${applicationId}/details`,
        search: location.search,
      });
    }
    const [results] = context;
    const currentIndex = _findIndex(results, (app) => {
      return app.ApplicationID === applicationId;
    });
    setCurrentIndex(currentIndex);
    setMaxIndex(results.length - 1);
    const nextIndex = currentIndex !== results.length - 1 ? currentIndex + 1 : false;
    const previousIndex = currentIndex !== 0 ? currentIndex - 1 : false;
    const onKeyDown = (e) => {
      if (
        document.activeElement.tagName.toUpperCase() === 'INPUT' ||
        e.target.tagName.toUpperCase() === 'INPUT'
      )
        return;
      if (e?.key === 'ArrowRight') {
        const nextAppId = results[nextIndex]?.ApplicationID;
        if (nextAppId) {
          navigateTo(nextAppId);
        }
      } else if (e?.key === 'ArrowLeft') {
        const prevAppId = results[previousIndex]?.ApplicationID;
        if (prevAppId) {
          navigateTo(prevAppId);
        }
      }
    };
    window.addEventListener('keydown', onKeyDown);
    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  }, [context, applicationId, vacancyId, navigate, location.search]);

  useEffect(() => {
    if (context === undefined && !context?.hasOwnProperty('carouselRef')) return;
    const currentIndex = context.carouselRef?.current?.state?.currentSlide;
    setCurrentIndex(currentIndex);
  }, [context]);

  // Get infoMutator for header
  const {
    data: vacancyData = {},
    mutate: infoMutator
  } = useAfasData(`/vacancies/${vacancyId}/info`, {
    lang: i18n.language
  });
  // Get open applications mutator
  const { mutate: openMutator } = useAfasData(`/vacancy/${vacancyId}/applications/open`, {});
  // Get list applications mutator for grid view
  const { mutate: listMutator } = useAfasData(`/vacancy/${vacancyId}/applications/list`, {});

  function getBack() {
    let dPath = '';
    setDialogState(false);
    if (location.pathname.includes('cards')) {
      dPath = 'cards';
    } else {
      dPath = 'overview';
    }
    navigate(
      {
        pathname: `/vacancies/${vacancyId}/applications/${dPath}`,
        search: location.search,
      },
      { state: { slideIndex: currentIndex } }
    );
  }

  // Get comments
  const {
    data: commentData = [],
    isLoading: commentDataIsLoading,
    mutate: mutateReaction,
  } = useAfasData(`/vacancies/${vacancyId}/applications/${applicationId}/comments`, {});
  // Get application details data
  const { data: applicationData = [], isLoading: applicationIsLoading } = useAfasData(
    `/vacancy/${vacancyId}/application/${applicationId}/details`,
    {
      lang: i18n.language
    }
  );

  const { data: attachmentData = [], isLoading: attachmentsIsLoading } = useAfasData(
    `/files/${applicationId}/list`,
    {}
  );

  const [dialogState, setDialogState] = useState(true);
  const pdfAttachments = attachmentData.filter((a) => a.AttachmentName.endsWith('.pdf'));
  const cvWords = [
    'cv',
    'c-v',
    'c v',
    'cvs',
    'curriculum vitae',
    'curriculumvitae',
    'curriculum_vitae',
    'curriculum-vitae',
    'resume',
    'resumé',
  ];
  const cvPdfs =
    pdfAttachments.filter((a) => cvWords.some((word) => a.AttachmentName.toLowerCase().includes(word)))
      ?.length > 0
      ? pdfAttachments.filter((a) => cvWords.some((word) => a.AttachmentName.toLowerCase().includes(word)))
      : pdfAttachments;

  const mainAttachment = cvPdfs?.length > 0 ? cvPdfs[0] : false;

  const role = applicationData?.Role; // 'chair_person' | 'reviewer' | 'other_responsible_person' | 'contact_person'
  const genderWord = useWords(applicationData?.Gender);

  /**
   * {members, comments}
   * members: {
   *   PersonID: string;
   *   PersonName: string;
   *   PersonImage: string | null;
   * }
   * comments: {
   *   PersonID: string;
   *   Date: string;
   *   Rating: number;
   *   Comment: string;
   * }
   */

  return (
    <>
      <style>
        {`#root {
          position: absolute !important; 
        }`}
      </style>
      {context && !location.pathname.includes('cards') && (
        <>
          {currentIndex !== 0 && (
            <Box
              sx={{
                position: 'absolute',
                left: '1.6em',
                top: '450px',
                transform: 'translateY(-50%)',
                zIndex: '1301',
              }}
            >
              <Tooltip arrow title={t('vacancies.swiper.prev')}>
                <SwiperIconButton
                  className="ev_grid-prev-button"
                  onClick={() => {
                    if (context === undefined) return;
                    const [results] = context;
                    const currentIndex = _findIndex(results, (app) => {
                      return app.ApplicationID === applicationId;
                    });
                    const previousIndex = currentIndex !== 0 ? currentIndex - 1 : false;
                    const prevAppId = results[previousIndex]?.ApplicationID;
                    if (prevAppId) {
                      navigate({
                        pathname: `/vacancies/${vacancyId}/applications/overview/${prevAppId}/details`,
                        search: location.search,
                      });
                    }
                  }}
                >
                  <ArrowBackIosRoundedIcon sx={{ fontSize: '1.25rem' }} />
                </SwiperIconButton>
              </Tooltip>
            </Box>
          )}
          {currentIndex !== maxIndex && (
            <Box
              sx={{
                position: 'absolute',
                right: '1.6em',
                top: '450px',
                transform: 'translateY(-50%)',
                zIndex: '1301',
              }}
            >
              <Tooltip arrow title={t('vacancies.swiper.next')}>
                <SwiperIconButton
                  className="ev_grid-next-button"
                  onClick={() => {
                    if (context === undefined) return;
                    const [results] = context;
                    const currentIndex = _findIndex(results, (app) => {
                      return app.ApplicationID === applicationId;
                    });
                    const nextIndex = currentIndex !== results.length - 1 ? currentIndex + 1 : false;
                    const nextAppId = results[nextIndex]?.ApplicationID;
                    if (nextAppId) {
                      navigate({
                        pathname: `/vacancies/${vacancyId}/applications/overview/${nextAppId}/details`,
                        search: location.search,
                      });
                    }
                  }}
                >
                  <ArrowForwardIosRoundedIcon sx={{ fontSize: '1.25rem' }} />
                </SwiperIconButton>
              </Tooltip>
            </Box>
          )}
        </>
      )}
      <Dialog
        open={dialogState}
        slots={{ backdrop: ApplicationDetailBackDrop }}
        slotProps={{ backdrop: { className: 'backdrop-detail-dialog' } }}
        disableAutoFocus={true}
        onClose={() => {
          document.getElementById('root').style.position = 'relative !important';
          getBack();
        }}
        sx={(theme) => ({
          width: '100%',
          overflow: 'visible',
          marginTop: '1.5rem',
          marginBottom: '3rem',
          boxShadow: 'unset',
          '& .MuiDialog-container': {
            width: '100%',
          },
          '& .MuiDialog-paper': {
            overflowX: 'initial',
            boxShadow: 'rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px',
            borderRadius: '0px',
            marginBottom: 0,
            marginTop: 0,
            maxWidth: '100%',
            marginLeft: 0,
            marginRight: 0,
            flex: 1,
            bgcolor: theme.vars.palette.grey.A100,
          },
        })}
        className="use-png-cutout-bg"
        id="detail-dialog"
      >
        <Box
          className="fixed-close-button"
          sx={{
            position: 'absolute',
            top: '2em',
            right: '2em',
          }}
        >
          <Fab
            size="small"
            color="light"
            sx={{
              backgroundColor: '#FFF',
            }}
            onClick={() => {
              document.getElementById('root').style.position = 'relative';
              getBack();
            }}
          >
            <XIcon width="24" height="24" />
          </Fab>
        </Box>
        <DialogContent
          sx={{
            minHeight: '800px',
            marginBottom: '3rem',
            padding: '0',
          }}
        >
          {applicationIsLoading && (
            <>
              <Box mb={8}>
                <Skeleton variant="rounded" height={190} />
              </Box>
              <Box
                sx={{
                  padding: '3rem 3rem 0rem 3rem',
                }}
                mb={6}
              >
                <Skeleton variant="rounded" height={200} />
              </Box>
              <Box
                sx={{
                  padding: '3rem 3rem 0rem 3rem',
                }}
                mb={6}
              >
                <Skeleton variant="rounded" height={500} />
              </Box>
            </>
          )}
          {applicationData && !applicationIsLoading && (
            <>
              <DetailDialogHeader applicationData={applicationData} />
              <Box
                sx={{
                  padding: '3rem 3rem 0rem 3rem',
                }}
              >
                <Stack
                  onClick={() => {
                    document.getElementById('root').style.position = 'relative';
                    getBack();
                  }}
                  direction="row"
                  alignItems="center"
                  spacing={1}
                  mr={2}
                  mb={5}
                >
                  <BackIconButton>
                    <ArrowBackIosRoundedIcon sx={(theme) => ({ fontSize: '.5em' })} />
                  </BackIconButton>
                  <Typography
                    variant="h6"
                    sx={(theme) => ({
                      display: 'inline-block',
                      textDecoration: 'none',
                      cursor: 'pointer',
                    })}
                  >
                    {t('main.backtocandidates')}
                  </Typography>
                </Stack>
                {commentData && applicationData && (
                  <DetailDialogSummary
                    role={role}
                    applicationData={applicationData}
                    members={commentData.members}
                  />
                )}
                <Stack direction="row" spacing={4} mt={4}>
                  <Box
                    sx={(theme) => ({
                      flex: '1 1 50%',
                      padding: '2.5em 2em 2.5em 3em',
                      bgcolor: theme.vars.palette.background.default,
                    })}
                  >
                    <DetailDialogSectionTitle
                      sx={(theme) => ({ color: `${theme.vars.palette.text.primary} !important` })}
                      variant="h1"
                      className="detail-dialog-section-title"
                    >
                      {t('cards.card.personal.title')}
                    </DetailDialogSectionTitle>
                    <List mb={4}>
                      {applicationData?.Initials && (
                        <CardListItemPersonal
                          label={t('cards.card.personal.initials')}
                          answer={applicationData?.Initials}
                        />
                      )}
                      {applicationData?.FirstName && (
                        <CardListItemPersonal
                          label={t('cards.card.personal.firstname')}
                          answer={applicationData?.FirstName}
                        />
                      )}
                      {applicationData?.GivenName && (
                        <CardListItemPersonal
                          label={t('cards.card.personal.givenname')}
                          answer={applicationData?.GivenName}
                        />
                      )}
                      {applicationData?.Prefix && (
                        <CardListItemPersonal
                          label={t('cards.card.personal.prefix')}
                          answer={applicationData?.Prefix}
                        />
                      )}
                      {applicationData?.LastName && (
                        <CardListItemPersonal
                          label={t('cards.card.personal.lastname')}
                          answer={applicationData?.LastName}
                        />
                      )}
                      {applicationData?.Gender && (
                        <CardListItemPersonal
                          label={t('cards.card.personal.salutation')}
                          answer={genderWord}
                        />
                      )}
                      {applicationData?.DateOfBirth && (
                        <CardListItemPersonal
                          label={t('cards.card.personal.dob')}
                          answer={dayjs(applicationData?.DateOfBirth)
                            .locale(i18n.language)
                            .format('D MMMM, YYYY')}
                        />
                      )}
                      {applicationData?.Language && (
                        <CardListItemPersonal
                          label={t('cards.card.personal.language')}
                          answer={applicationData?.Language}
                        />
                      )}
                      {applicationData?.Nationality && (
                        <CardListItemPersonal
                          label={t('cards.card.personal.nationality')}
                          answer={applicationData?.Nationality}
                        />
                      )}
                      {applicationData?.TelephoneNumber && (
                        <CardListItemPersonal
                          label={t('cards.card.personal.telephone')}
                          answer={applicationData?.TelephoneNumber}
                        />
                      )}
                      {applicationData?.Email && (
                        <CardListItemPersonal
                          label={t('cards.card.personal.email')}
                          answer={
                            <Link to={`mailto:${applicationData?.Email}`}>{applicationData?.Email}</Link>
                          }
                        />
                      )}
                      {applicationData?.LevelOfEnglish && (
                        <CardListItemPersonal
                          label={t('cards.card.personal.levelen')}
                          answer={applicationData?.LevelOfEnglish}
                        />
                      )}
                      {applicationData?.ApplicationVideoUrl && (
                        <CardListItemPersonal
                          label={t('cards.card.personal.applicationvid')}
                          answer={
                            <Link to={applicationData?.ApplicationVideoUrl} target="_blank">
                              {applicationData?.ApplicationVideoUrl}
                            </Link>
                          }
                        />
                      )}
                      {applicationData?.VacancyReference && (
                        <CardListItemPersonal
                          label={t('cards.card.personal.reference')}
                          answer={applicationData?.VacancyReference}
                        />
                      )}
                    </List>
                  </Box>
                </Stack>
              </Box>
            </>
          )}
          {attachmentsIsLoading && (
            <Box
              sx={{
                padding: '2rem 3rem 0',
              }}
            >
              <Box
                sx={(theme) => ({
                  padding: '2.5em 2em 2.5em 3em',
                  bgcolor: theme.vars.palette.grey.A100,
                })}
              >
                <Skeleton mt={4} mb={2} variant="rounded" height={640} />
              </Box>
            </Box>
          )}
          {attachmentData && !attachmentsIsLoading && (
            <Box
              sx={{
                padding: '2rem 3rem 0',
              }}
            >
              <Box
                sx={(theme) => ({
                  padding: '2.5em 2em 2.5em 3em',
                  bgcolor: theme.vars.palette.background.default,
                })}
              >
                <Stack direction="row" spacing={6}>
                  <Box
                    sx={{
                      flex: '1 1 30%',
                    }}
                  >
                    <DetailDialogSectionTitle
                      sx={(theme) => ({ color: `${theme.vars.palette.text.primary} !important` })}
                      variant="h1"
                      className="detail-dialog-section-title"
                    >
                      {t('cards.card.attachment.title')}
                    </DetailDialogSectionTitle>
                    <List>
                      {attachmentData.map((attachment) => (
                        <ListItem
                          key={attachment.AttachmentID}
                          sx={{
                            paddingLeft: 0,
                            paddingRight: 0,
                          }}
                        >
                          <Link
                            target="_blank"
                            to={`${insiteUrl}/file/download/default/${attachment.AttachmentCode}/${attachment.EscapedAttachmentName}`}
                          >
                            {attachment.AttachmentName}
                            <LaunchOutlinedIcon
                              sx={(theme) => ({
                                fontSize: '.8em',
                                marginLeft: '.5em',
                              })}
                            />
                          </Link>
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                  <Box
                    sx={{
                      flex: '1 1 70%',
                    }}
                  >
                    {mainAttachment && (
                      <>
                        <Box
                          mb={4}
                          sx={{
                            position: 'relative',
                            overflow: 'hidden',
                            '&:hover .MuiFab-root': {
                              bottom: '1em',
                              transform: 'translateY(0%)',
                            },
                          }}
                        >
                          <embed
                            style={{
                              minWidth: '100%',
                              minHeight: '500px',
                              borderRadius: '.95rem',
                              backgroundColor: 'rgba(0,0,0,0.05)',
                            }}
                            onLoad={function (e) {
                              e.target.style.minHeight = '500px';
                            }}
                            onError={function (e) {
                              e.target.style.display = 'none';
                            }}
                            src={`${process.env.REACT_APP_ENDPOINT_URL}/files/${applicationId}/${mainAttachment.AttachmentCode}?token=${token}`}
                            type="application/pdf"
                          />
                          <Link
                            target="_blank"
                            to={`${insiteUrl}/file/download/default/${mainAttachment.AttachmentCode}/${mainAttachment.EscapedAttachmentName}`}
                          >
                            <Tooltip arrow title={t('cards.card.attachment.open')}>
                              <Fab
                                size="small"
                                color="light"
                                aria-label="Open in new tab"
                                sx={{
                                  transition: 'all ease-in-out 250ms',
                                  position: 'absolute',
                                  bottom: '-1em',
                                  transform: 'translateY(100%)',
                                  right: '1em',
                                }}
                              >
                                <LaunchOutlinedIcon fontSize="small" />
                              </Fab>
                            </Tooltip>
                          </Link>
                        </Box>
                      </>
                    )}
                  </Box>
                </Stack>
              </Box>
            </Box>
          )}
          {attachmentData?.length > 0 && <Box mb={2}></Box>}
          <CardReviewSection
            commentData={commentData}
            commentDataIsLoading={commentDataIsLoading}
            applicationIsLoading={applicationIsLoading}
            applicationData={applicationData}
            currentIndex={currentIndex}
            mutateReaction={mutateReaction}
            vacancyData={vacancyData}
            listMutator={listMutator}
            openMutator={openMutator}
            infoMutator={infoMutator}
            mt={2}
            role={role}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}

export default VacancyDetailDialog;
