import { Box, Button, Stack } from "@mui/material";
import BackToOverview from "./BackToOverview";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const VacancyNavigation = ({
  children,
  active = 'cards'
}) => {
  const { t } = useTranslation('common');
  const params = useParams();
  let { search } = useLocation();
  const vacancyId = params.VacancyID;
  const navigate = useNavigate();
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="flex-start" spacing={2}>
      <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
        <BackToOverview />
        {children}
      </Stack>
      <Box sx={{ marginLeft: 'auto' }}>
        <Stack direction="row" spacing={2}>
          {/* <Box>
            <Button variant={active === "summary" ? "contained" : "outlined"} color="secondary" className="secondary-btn" onClick={() => {
              navigate({
                pathname: `/vacancies/${vacancyId}/overview/`,
                search: search,
              });
            }}>{t('vacancyoverview')}</Button>
          </Box> */}
          <Box>
            <Button variant={active === "cards" ? "contained" : "outlined"} color="secondary" className="secondary-btn" onClick={() => {
              if (active !== 'cards') {
                navigate({
                  pathname: `/vacancies/${vacancyId}/applications/cards/`,
                  search: search,
                })
              }
            }}>{t('toassess')}</Button>
          </Box>
          <Box>
            <Button variant={active === "overview" ? "contained" : "outlined"} color="secondary" className="secondary-btn" onClick={() => {
              if (active !== 'overview') {
                navigate({
                  pathname: `/vacancies/${vacancyId}/applications/overview/`,
                  search: search,
                })
              }
            }}>{t('candidatesoverview')}</Button>
          </Box>
        </Stack>
      </Box>
    </Stack>
  );
}

export default VacancyNavigation;