import {
  TableRow,
  TableCell,
  styled,
  Typography,
  Button,
  Stack,
  Box,
  Avatar,
  Checkbox,
  Rating,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import OnHoldIcon from '../images/OnHoldIcon.svg';
import RejectedIcon from '../images/RejectedIcon.svg';
import CheckIcon from '../images/Check_Icon.svg';
import { useContext, useRef, useState } from 'react';
import { InSiteContext } from '../context/InSiteContext';
import CompetencePopover from './CompetencePopover';
import { CheckBoxIcon, CheckBoxIconChecked } from './Icons';

function getAverageRating(candidate) {
  if (candidate.Competence1Average === null || candidate.Competence1Average === -1) return 0;
  const competences = {
    Competence1Average: candidate.Competence1Average,
    Competence2Average: candidate.Competence2Average,
    Competence3Average: candidate.Competence3Average,
    Competence4Average: candidate.Competence4Average,
    Competence5Average: candidate.Competence5Average,
  }
  const values = Object.values(competences).filter(value => ![null, -1].includes(value));
  // Calculate the average
  const average = values.length > 0 ? values.reduce((sum, value) => sum + value, 0) / values.length : null;
  return average;
}

function CandidateListEntry({ view = 'candidates-list', candidate, selectedRows, handleCheckboxChange, vacancy }) {
  const { t } = useTranslation('common');
  const insiteUrl = useContext(InSiteContext);

  const [open, setOpen] = useState(false);
  const anchorEl = useRef();

  const StyledCell = styled(TableCell)({
    border: 'none',
    paddingInline: '2em !important',
    paddingBlock: '2em !important',
    alignItems: 'start',
    textAlign: 'left',
  });

  const RatingIcon = ({ color, count, icon }) => {
    return (
      <Box
        sx={{
          textAlign: 'center',
          maxWidth: '120px',
          display: 'block',
          width: 48,
          height: 48,
          marginLeft: 'auto',
          marginRight: 'auto',
          borderRadius: '100%',
          backgroundColor: '#F5F5F5',
        }}
      >
        <Avatar
          alt="icon"
          src={icon}
          sx={{
            width: 18,
            height: 18,
            marginLeft: 'auto',
            marginRight: 'auto',
            bgcolor: 'transparent',
            borderRadius: '0',
            marginTop: '4px',
          }}
        />
        <Typography
          variant="h4"
          color={color}
          sx={{
            fontSize: '1rem !important',
            fontWeight: 500,
            transition: 'all ease-in-out 250ms',
            color: `${color} !important`,
          }}
        >
          {count}
        </Typography>
      </Box>
    );
  };

  // const getDateString = (date) => {
  //   date = new Date(date);
  //   const weekday = date.toLocaleString('default', { weekday: 'long' });
  //   const capWeekday = weekday.charAt(0).toUpperCase() + weekday.slice(1);
  //   const day = date.getDate();
  //   const month = date.toLocaleString('default', { month: 'long' });
  //   const capMonth = month.charAt(0).toUpperCase() + month.slice(1);
  //   const year = date.getFullYear();

  //   return `${capWeekday} ${day} ${capMonth} ${year}`;
  // };

  return (
    <TableRow key={candidate.SubjectID} className="table-row-legend" width="100%" sx={{ background: 'white' }}>
      {handleCheckboxChange && selectedRows && (
        <StyledCell>
          <Checkbox
            icon={<CheckBoxIcon sx={{
              fill: 'none',
            }} />}
            checkedIcon={<CheckBoxIconChecked />}
            color="secondary"
            checked={selectedRows?.includes(candidate.ApplicationID)}
            onChange={() => handleCheckboxChange(candidate.ApplicationID)}
          />
        </StyledCell>
      )}
      <StyledCell>
        <Typography variant="body1">
          {candidate.ApplicantName}
        </Typography>
      </StyledCell>
      <StyledCell>
        <Stack direction="row" spacing={2}>
          <RatingIcon color="#1DC124" count={candidate.SuggestInvite} icon={CheckIcon} />
          <RatingIcon color="#319DD3" count={candidate.SuggestOnHold} icon={OnHoldIcon} />
          <RatingIcon color="#EA0D0D" count={candidate.SuggestReject} icon={RejectedIcon} />
        </Stack>
      </StyledCell>
      {view !== 'vacancy-list' && (
        <StyledCell>
          <Typography variant="body1">
            {candidate.VacancyTitle}
          </Typography>
        </StyledCell>
      )}
      <StyledCell>
        <Typography variant="body1">
          {t(`candidates.status.${candidate.StatusCode}`)}
        </Typography>
      </StyledCell>
      <StyledCell>
        <Box>
          <Box
            // onClick={handleClick}
            ref={anchorEl}
            onClick={() => setOpen(true)}
            sx={(theme) => ({
              backgroundColor: theme.vars.palette.grey.A100,
              borderRadius: '3em',
              padding: '.25rem .75rem',
              display: 'inline-flex',
              alignItems: 'center',
              cursor: 'pointer',
            })}
          >
            {candidate.Competence1Average === '?' ? (
              <>
                <Typography variant="body1" color="initial">
                  ?
                </Typography>
              </>
            ) : (
              <Rating
                size="small"
                name="read-only"
                max={5}
                value={getAverageRating(candidate)}
                readOnly
                sx={{
                  fontSize: '1.5rem',
                }}
              />
            )}
          </Box>
          {candidate && candidate.Competence1Average && (
            <CompetencePopover
              // onClose={handleClose}
              onClose={() => setOpen(false)}
              applicationData={{
                Competence1: vacancy?.Competence1 ?? candidate.Competence1,
                Competence2: vacancy?.Competence2 ?? candidate.Competence2,
                Competence3: vacancy?.Competence3 ?? candidate.Competence3,
                Competence4: vacancy?.Competence4 ?? candidate.Competence4,
                Competence5: vacancy?.Competence5 ?? candidate.Competence5,
              }}
              member={{
                Competence1: candidate.Competence1Average,
                Competence2: candidate.Competence2Average,
                Competence3: candidate.Competence3Average,
                Competence4: candidate.Competence4Average,
                Competence5: candidate.Competence5Average,
              }}
              anchorEl={() => anchorEl.current}
              open={open}
            />
          )}
        </Box>
      </StyledCell>
      {/* <StyledCell>
        <Typography variant="body1">
          {getDateString(candidate.Date)}
        </Typography>
      </StyledCell> */}
      <StyledCell width="10%">
        <Button
          aria-label={t('candidates.list.goto')}
          size="small"
          color="primary"
          variant="contained"
          sx={{
            '&.MuiButtonBase-root': {
              minWidth: 'unset',
              width: '50%',
              height: 'auto',
            },
          }}
          href={`${insiteUrl}/sollicitant-stamkaart-prs?EmId=${candidate.EmId}&BcId=${candidate.BcId}`}
          target="_blank"
        >
          <ArrowForwardIosIcon fontSize="small" />
        </Button>
      </StyledCell>
    </TableRow>
  );
}

export default CandidateListEntry;
