import { Avatar, Box, ListItem, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/nl';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useTranslation } from 'react-i18next';
import CommentRating from './CommentRating';
import { hasCompetences } from './CardReviewSection';
import CompetenceRatings from './CompetenceRatings';

const CommentListItem = ({ comment, member, vacancyData }) => {
  dayjs.extend(relativeTime);

  /**
   *
   * Example: member object
   *
   * Competence1: 2
   * Competence2: 2
   * Competence3: 3
   * Competence4: 1
   * Competence5: null
   * Date: "2023-06-07T13:08:31.000Z"
   * IsSelf: false
   * PersonID: "76462886"
   * PersonImage: null
   * PersonName: "Gerrit Brem"
   * Rating: 3
   */

  const { i18n } = useTranslation('common');
  return (
    <ListItem
      key={comment.Date}
      sx={(theme) => ({
        marginBottom: '1rem',
        borderRadius: '1rem',
        padding: '2rem 1rem 1.25rem 2rem',
        display: 'block',
        backgroundColor: theme.vars.palette.grey.A100,
      })}
    >
      <Stack direction="row" spacing={2} alignItems="flex-start" justifyContent="space-between">
        <Stack direction="row" spacing={2} alignItems="flex-start" sx={{ flex: 1 }}>
          {/* Person Avatar */}
          {member?.PersonImage && (
            <Avatar
              alt={member?.PersonName}
              src={`data:image/jpeg;base64,${member.PersonImage}`}
              sx={{ width: 48, height: 48 }}
            />
          )}
          {!member?.PersonImage && (
            <Avatar alt={member?.PersonName} sx={{ width: 48, height: 48 }}>
              {member?.PersonName.charAt(0)}
            </Avatar>
          )}
          <Box>
            {/* Date and name */}
            <Typography variant="body1" sx={{ fontSize: '.9rem', marginBottom: '.25em' }}>
              {dayjs().locale(i18n.language).to(dayjs(comment.Date))}
            </Typography>
            <Typography variant="body1" sx={{ fontSize: '.9rem' }}>
              {member?.PersonName}
            </Typography>
          </Box>
        </Stack>
        {/* Advise and competence ratings */}
        <Stack direction="row" spacing={2} alignItems="flex-start" justifyContent="flex-end">
          <CommentRating rating={member.Rating} />
          {hasCompetences(vacancyData) && (
            <CompetenceRatings member={member} competences={{
              Competence1: vacancyData?.Competence1,
              Competence2: vacancyData?.Competence2,
              Competence3: vacancyData?.Competence3,
              Competence4: vacancyData?.Competence4,
              Competence5: vacancyData?.Competence5,
            }} />
          )}
        </Stack>
      </Stack>
      {comment.Comment && (
        <Box mt={3}>
          <Typography variant="body1" sx={{ fontSize: '.9rem' }}>
            {comment.Comment}
          </Typography>
        </Box>
      )}
    </ListItem>
  );
};

export default CommentListItem;
