import { Outlet, useParams } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import {
  IconButton,
  Stack,
  LinearProgress,
  Grid,
  Typography,
  Divider,
  InputBase,
} from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import useAfasData from '../hooks/useAfasData';
import { useTranslation } from 'react-i18next';
import OverviewHeaderTitle from '../components/OverviewHeaderTitle';
import VacancyNavigation from '../components/VacancyNavigation';
import FuzzySearch from 'fuzzy-search';

/**
 *  Rejected = '005',
 *  SelectionCommittee = '002',
 *  Invited = '003',
 *  OnHold = '004',
 *  Completed = '007',
 *  BeingProcessed = '002',
 *  RejectedByChairPerson = '008',
 *  Employed = '006',
 *  OnHoldEmailSent = '009',
 */
export const getStatusColor = (statusCode) => {
  switch (statusCode) {
    case '005':
      return '#EA0D0D';
    case '008':
      return '#EA0D0D';
    case '002':
      return '#afafaf';
    case '003':
      return '#1DC124';
    case '004':
      return '#319DD3';
    case '009':
      return '#319DD3';
    default:
      return '#888888';
  }
};

function VacancySummary() {
  const { t, i18n } = useTranslation('common');
  const params = useParams();
  const [searchValue, setSearchValue] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
  const [applications, setApplications] = useState([]);

  useEffect(() => {
    const debounceId = setTimeout(() => {
      setDebouncedSearchTerm(searchValue);
    }, 1000);
    return () => {
      clearTimeout(debounceId);
    };
  }, [searchValue]);

  useEffect(() => {
    if (debouncedSearchTerm !== '') {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'searchOverview',
        search: debouncedSearchTerm,
      });
    }
  }, [debouncedSearchTerm]);

  const vacancyId = params.VacancyID;

  const {
    data: applicationData = [],
    isLoading: applicationIsLoading,
  } = useAfasData(`/vacancy/${vacancyId}/applications/list`, {
    lang: i18n.language
  });

  const {
    data: vacancyData = {},
    isLoading: vacancyIsLoading,
  } = useAfasData(`/vacancies/${vacancyId}/info`, {
    lang: i18n.language
  });

  /**
   * TotalCandidates: 32
   * TotalInvited: 9
   * TotalOnHold: 11
   * TotalRejected: 11
   * TotalReviewOpen: 1
   * VacancyID: 762
   * VacancyTitle: "Assistant Professor: Artificial Intelligence in Biomechanics and Robotics"
   * Competence1: mainVacancy.Competence1 || '',
   * Competence2: mainVacancy.Competence2 || '',
   * Competence3: mainVacancy.Competence3 || '',
   * Competence4: mainVacancy.Competence4 || '',
   * Competence5: mainVacancy.Competence5 || '',
   * Role: vacancyRole,
   */

  useEffect(() => {
    if (applicationData?.length < 1) return setApplications([]);
    let result = [...applicationData];
    const applicationSearcher = new FuzzySearch(result, ['ApplicantName'], {
      caseSensitive: false,
      sort: false,
    });
    result = applicationSearcher.search(searchValue);
    setApplications(result);
  }, [applicationData, searchValue]);

  useEffect(() => {
    applicationData.map((application) => {
      if (application.Rating === '-' || application.Rating === null) application.Rating = 0;
      if (application.Competence1Average === null) application.Competence1Average = -1;
      if (application.Competence2Average === null) application.Competence2Average = -1;
      if (application.Competence3Average === null) application.Competence3Average = -1;
      if (application.Competence4Average === null) application.Competence4Average = -1;
      if (application.Competence5Average === null) application.Competence5Average = -1;
      return application;
    });
  }, [applicationData]);

  return (
    <>
      <Box mb={4} mt={2}>
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          justifyContent="space-between"
          alignItems="start"
          spacing={4}
        >
          <OverviewHeaderTitle vacancyData={vacancyData} vacancyIsLoading={vacancyIsLoading} />
        </Stack>
      </Box>

      <VacancyNavigation active={'summary'}>
        <Box
          sx={(theme) => ({
            display: 'inline-block',
            boxShadow: theme.shadows[1],
          })}
          className="bordered"
        >
          <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
            <SearchIcon />
          </IconButton>
          <InputBase
            sx={{
              flex: 1,
              pl: 1,
              fontSize: '16px',
              letterSpacing: 'normal',
            }}
            className="bordered"
            placeholder={t('vacancies.overview.search')}
            inputProps={{ 'aria-label': 'Search by name' }}
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </Box>
      </VacancyNavigation>

      <Box my={4}>
        <Divider />
      </Box>

      <Box pb={5} mt={5}>
        {applicationIsLoading && <LinearProgress mt={2} />}
        {applications?.length === 0 && !applicationIsLoading && (
          <Typography variant="h4" color="initial" align="center">
            {t('cards.card.noresults')}
          </Typography>
        )}
        {applications && vacancyData && !applicationIsLoading && (
          <Grid
            container
            spacing={6}
            alignItems="stretch"
            justifyContent="flex-start"
            direction="row"
            className="overview-grid"
            mt={2}
          >
            {applications.map((application) => {
              return (
                <Grid item xs={12} md={4} lg={3} mb={3} key={application.ApplicationID}>
                  <Typography variant="h3" color="initial">{application.ApplicantName}</Typography>
                </Grid>
              );
            })}
          </Grid>
        )}
      </Box>
      <Outlet context={[applications]} />
    </>
  );
}

export default VacancySummary;
