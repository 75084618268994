import { SvgIcon } from '@mui/material';

export const XIcon = (props) => {
  return (
    <svg {...props} fill={props?.stroke || '#EA0D0D'} viewBox="0 0 32 32">
      <polygon points="22.6,9.2 16.2,15.5 22.6,21.8 21.8,22.6 15.5,16.2 9.2,22.6 8.4,21.8 14.8,15.5 8.4,9.2 9.2,8.4 15.5,14.8 
      21.8,8.4 "/>
    </svg>
  );
};

export const StarIcon = (props) => {
  return (
    <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" width="47.239" height="44.546" viewBox="0 0 47.239 44.546">
      <path id="star" d="M50.231,20.3a1.814,1.814,0,0,0-1.826-1.278H33.07L28.324,4.778A1.754,1.754,0,0,0,26.681,3.5h0a1.563,1.563,0,0,0-1.643,1.278l-4.929,14.24H4.955A2.2,2.2,0,0,0,3.13,20.3,1.733,1.733,0,0,0,3.86,22.3L16.274,31.25,11.528,45.672a1.733,1.733,0,0,0,.73,2.008,2.6,2.6,0,0,0,1.1.365,2.6,2.6,0,0,0,1.1-.365l12.414-8.946,12.414,8.946a2.6,2.6,0,0,0,1.1.365,2.6,2.6,0,0,0,1.1-.365,2.172,2.172,0,0,0,.73-2.008l-4.747-14.24,12.414-8.946A2.988,2.988,0,0,0,50.231,20.3ZM35.444,30.154a.878.878,0,0,0-.365,1.1l4.929,14.97L27.046,36.909a.913.913,0,0,0-1.1,0L12.988,46.22l4.929-14.97c.183-.365,0-.73-.365-1.1l-12.6-9.311H20.839a1,1,0,0,0,.913-.548L26.681,5.143l4.929,14.97a1,1,0,0,0,.913.548H48.588Z" transform="translate(-3.064 -3.5)" fill={props?.stroke || '#EC7A08'} />
    </SvgIcon>
  );
};

export const CheckIcon = (props) => {
  return (
    <svg {...props} viewBox="0 0 32 32">
      <path
        d="M7.7,17.1l4.6,4.6L24.7,9.2"
        strokeWidth="1.8"
        fill="none"
        stroke={props?.stroke || '#1DC124'}
      />
    </svg>
  );
};

export const OnHoldIcon = (props) => {
  return (
    <svg {...props} viewBox="0 0 32 32">
      <path d="M15,27h9v1h-9V27z M15.178,25.945c-0.066-0.021-1.648-0.575-4.727-2.611c-2.301-1.521-2.663-2.591-3.103-4.35  c-0.293-1.172-0.659-2.631-2.013-5.063c-0.642-1.151-0.226-1.928,0.08-2.287c0.368-0.433,0.895-0.68,1.449-0.68  c0.399,0,0.772,0.127,1.081,0.368c1.276,1,1.908,2.493,2.415,3.693c0.375,0.889,0.764,1.808,1.211,1.955l0.139,0.025  c0.141,0,0.293-0.381,0.293-0.997V4c0-1.103,0.897-2,2-2c0.412,0,0.794,0.125,1.112,0.338C15.386,1.56,16.124,1,16.989,1  c0.877,0,1.625,0.561,1.9,1.339C19.208,2.125,19.591,2,20.002,2c1.104,0,2,0.897,2,2v0.227c0.271-0.14,0.602-0.227,1-0.227  c0.996,0,2,0.773,2,2.5l-0.001,1.405c-0.004,1.455-0.008,3.448,0.025,3.757c0.123,0.737,0.248,1.381,0.363,1.976  c0.343,1.768,0.612,3.165,0.612,5.862c0,3.87-2.198,6.113-2.292,6.207C23.522,25.895,23.268,26,23.002,26h-7.5  C15.392,26,15.282,25.982,15.178,25.945z M11.002,22.5c3.008,1.988,4.5,2.5,4.5,2.5h7.5c0,0,2-2,2-5.5s-0.465-4.696-0.965-7.696  C23.98,11.463,24.002,8.5,24.002,6.5c0-1.098-0.5-1.5-1-1.5c-1,0-1,0.875-1,0.875v6.75c0,0.5-1,0.5-1,0V4c0-0.553-0.448-1-1-1  c-0.553,0-1,0.447-1,1v7.625c0,0.5-1,0.5-1,0V3c0-0.553-0.465-1-1.013-1s-0.987,0.447-0.987,1v8.625c0,0.5-1,0.5-1,0V4  c0-0.553-0.448-1-1-1s-1,0.447-1,1v12c0,1.729-0.887,2.205-1.746,1.922c-1.741-0.575-1.765-4.116-3.929-5.811  c-0.334-0.262-0.847-0.188-1.15,0.169c-0.304,0.358-0.175,0.786,0.031,1.155C9.48,19.312,7.314,20.063,11.002,22.5z"
        fill={props?.fill || '#319DD3'}
      />
    </svg>
  );
};

export const TotalAppliedIcon = (props) => {
  return (
    <svg {...props} viewBox="0 0 32 32">
      <path d="M30,28c0-4.838-3.127-8.938-7.467-10.409L19,21.125l-3.529-3.529c-1.506,0.514-2.87,1.336-4.005,2.404H3.08
	c0.486-3.391,3.395-6,6.92-6v-1c-2.209,0-4-1.791-4-4s1.791-4,4-4c1.029,0,1.958,0.399,2.666,1.038l0.669-0.743
	c-0.886-0.797-2.045-1.296-3.33-1.296c-2.76,0-4.998,2.238-4.998,4.998c0,1.888,1.059,3.512,2.604,4.362C4.359,14.375,2,17.411,2,21
	h8.515C8.944,22.902,8,25.341,8,28H30z M15.237,18.736L19,22.5l3.764-3.764c3.361,1.367,5.813,4.509,6.187,8.264H9.05
	C9.423,23.245,11.875,20.104,15.237,18.736z M19,17c3.313,0,6-2.687,6-6s-2.688-6-6-6c-3.313,0-6,2.687-6,6S15.687,17,19,17z M19,6
	c2.762,0,5,2.238,5,5s-2.238,5-5,5c-2.762,0-5-2.238-5-5S16.238,6,19,6z"
        transform="translate(-0.5 -7)"
        fill={props?.fill || '#4e1370'}
      />
    </svg>
  );
};

export const IdentityIcon = (props) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="37.663" height="40.802" viewBox="0 0 37.663 40.802">
      <path id="public_document" d="M41.663,43.8s0-10.985-6.277-10.985l-3.139,3.139-3.139-3.139c-3.438,0-4.989,3.3-5.69,6.277H6.354a.777.777,0,0,1-.785-.785V5.354a.777.777,0,0,1,.785-.785H19.693V13.2a2.351,2.351,0,0,0,2.354,2.354h8.631v4.931a6.2,6.2,0,1,0,1.569-.223V15.554c0-.989-.361-1.02-.926-1.569L21.262,3.926c-.549-.565-.6-.926-1.569-.926H6.354A2.351,2.351,0,0,0,4,5.354V38.309a2.351,2.351,0,0,0,2.354,2.354H23.112a23.4,23.4,0,0,0-.281,3.139ZM36.955,26.539a4.708,4.708,0,1,1-4.708-4.708A4.714,4.714,0,0,1,36.955,26.539ZM21.262,6.139l7.846,7.846H22.047a.777.777,0,0,1-.785-.785Zm7.252,28.3,2.624,2.624,1.109,1.109,1.109-1.109,2.624-2.624c2.914.565,3.766,5.276,4.014,7.792H24.5C24.741,39.715,25.587,35.006,28.514,34.441ZM16.554,15.554v1.569H8.708V15.554Zm6.277,6.277H8.708V20.262H22.832Zm0,4.708H8.708V24.97H22.832ZM8.708,29.678H22.832v1.569H8.708Z" transform="translate(-4 -3)" fill={props?.fill || '#FFF'} fill-rule="evenodd" />
    </svg>
  );
};

export const CommitteeReviewIcon = (props) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="48.101" height="50.025" viewBox="0 0 48.101 50.025">
      <path id="attendance_1_" data-name="attendance (1)" d="M21.316,33.785a9.62,9.62,0,1,0-9.62-9.62A9.619,9.619,0,0,0,21.316,33.785Zm0-17.316a7.7,7.7,0,1,1-7.7,7.7A7.7,7.7,0,0,1,21.316,16.468Zm3.848,19.24-3.848,3.848-3.848-3.848S4,37.633,4,51.1v1.924H38.633V51.1C38.633,37.633,25.164,35.709,25.164,35.709ZM5.924,51.1c0-9.82,8.133-12.629,10.913-13.3l4.479,4.479L25.8,37.794c2.784.66,10.907,3.431,10.907,13.307ZM41.519,3A10.582,10.582,0,1,0,52.1,13.582,10.584,10.584,0,0,0,41.519,3Zm0,19.24a8.658,8.658,0,1,1,8.658-8.658A8.669,8.669,0,0,1,41.519,22.24ZM46.61,9.053,39.595,16.07,36.428,12.9l-1.36,1.36L39.6,18.793l8.377-8.377Z" transform="translate(-4 -3)" fill={props?.fill || '#FFF'} />
    </svg>
  );
};

export const ExternalLinkIcon = (props) => {
  return (
    <svg {...props} width="41.02" height="41" viewBox="0 0 41.02 41">
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_2336" data-name="Rectangle 2336" width="41.02" height="41" fill="none" />
        </clipPath>
      </defs>
      <g id="Group_4000" data-name="Group 4000" transform="translate(0 0)">
        <g id="Group_3999" data-name="Group 3999" transform="translate(0 0)" clip-path="url(#clip-path)">
          <path
            id="Path_1305"
            data-name="Path 1305"
            d="M24.667,15.807l-2.658,2.657a5.536,5.536,0,0,0-2.4-1.374,5.322,5.322,0,0,0-5.34,1.309q-4.53,4.411-8.948,8.938a5.6,5.6,0,0,0,8,7.822c1.849-1.811,3.665-3.656,5.493-5.488a2.88,2.88,0,0,0,.245-.316l2.707,2.691c-.041.048-.106.128-.179.2-1.838,1.84-3.665,3.693-5.521,5.517a9.378,9.378,0,0,1-13.522-13q4.516-4.651,9.172-9.168a9.36,9.36,0,0,1,12.756,0c.075.071.144.152.193.206"
            transform="translate(0 0.445)"
            fill={props?.fill || '#1e2327'}
          />
          <path
            id="Path_1306"
            data-name="Path 1306"
            d="M15.81,24.744l2.661-2.654A5.485,5.485,0,0,0,20.8,23.452a5.38,5.38,0,0,0,5.476-1.35q4.462-4.367,8.829-8.831a5.263,5.263,0,0,0,1.183-5.92,5.6,5.6,0,0,0-9.226-1.867q-2.7,2.7-5.406,5.405c-.1.1-.208.2-.291.276l-2.72-2.709a4.671,4.671,0,0,0,.448-.354c1.816-1.808,3.608-3.638,5.442-5.427A9.374,9.374,0,0,1,37.95,15.766q-4.524,4.7-9.23,9.222A9.321,9.321,0,0,1,16,24.95c-.075-.071-.143-.153-.191-.206"
            transform="translate(0.537 0)"
            fill={props?.fill || '#1e2327'}
          />
        </g>
      </g>
    </svg>
  );
};

export const InformationIcon = (props) => {
  return (
    <svg width="23.991" height="23.992" viewBox="0 0 23.991 23.992" {...props}>
      <g id="Group_3942" data-name="Group 3942" transform="translate(-1567.413 -136.9)">
        <path
          id="Path_1195"
          data-name="Path 1195"
          d="M11.5,0a11.5,11.5,0,1,0,11.5,11.5A11.508,11.508,0,0,0,11.5,0m0,20.558A9.062,9.062,0,1,1,20.557,11.5,9.072,9.072,0,0,1,11.5,20.558"
          transform="translate(1567.913 137.4)"
          fill={props?.fill || '#fff'}
          stroke={props?.stroke || '#006b5a'}
          stroke-width="1"
        />
        <path
          id="Path_1196"
          data-name="Path 1196"
          d="M337.723,361.157H335.39a.71.71,0,0,0-.71.709v6.92a.709.709,0,0,0,.71.709h2.334a.709.709,0,0,0,.71-.709v-6.92a.71.71,0,0,0-.71-.709"
          transform="translate(1242.852 -213.377)"
          fill={props?.fill || '#fff'}
          stroke={props?.stroke || '#006b5a'}
          stroke-width="1"
        />
        <path
          id="Path_1197"
          data-name="Path 1197"
          d="M329.708,148.749a2.079,2.079,0,1,0,2.079,2.079,2.079,2.079,0,0,0-2.079-2.079"
          transform="translate(1249.7 -7.074)"
          fill={props?.fill || '#fff'}
          stroke={props?.stroke || '#006b5a'}
          stroke-width="1"
        />
      </g>
    </svg>
  );
};

export const ProgressIcon = (props) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <path
        d="M144.1 304.1L208 241.9l79.05 79.03C291.7 325.6 297.9 328 304 328s12.28-2.344 16.95-7.047l152-151.1c9.375-9.375 9.371-24.58-.0078-33.95c-9.371-9.365-24.56-9.361-33.93 .0078L304 270L224.9 191C215.6 181.7 200.4 181.7 191 191l-80 80c-9.375 9.375-9.375 24.56 0 33.95C120.4 314.4 135.6 314.3 144.1 304.1zM488 432H48V56C48 42.75 37.25 32 24 32S0 42.75 0 56V448c0 17.59 14.41 32 32 32h456c13.25 0 24-10.75 24-24S501.3 432 488 432z"
        fill="rgba(0, 0, 0, 0.54)"
      />
    </svg>
  );
};

export const HorizontalSwapIcon = (props) => {
  return (
    <svg {...props} viewBox="0 0 100 125">
      <g>
        <path d="M17.829,69H89v4H17.829l18.586,18.586l-2.828,2.828L10.171,71l23.415-23.414l2.828,2.828L17.829,69z M63.586,8.414   L82.171,27H11v4h71.171L63.586,49.586l2.828,2.828L89.829,29L66.414,5.586L63.586,8.414z"></path>
      </g>
    </svg>
  );
};

export const RollbackIcon = (props) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <path fill={props?.fill || '#fff'} d="M40 16C53.25 16 64 26.75 64 40v102.1C103.7 75.57 176.3 32.11 256.1 32.11C379.6 32.11 480 132.5 480 256s-100.4 223.9-223.9 223.9c-52.31 0-103.3-18.33-143.5-51.77c-10.19-8.5-11.56-23.62-3.062-33.81c8.5-10.22 23.66-11.56 33.81-3.062C174.9 417.5 214.9 432 256 432c97.03 0 176-78.97 176-176S353 80 256 80c-66.54 0-126.8 38.28-156.5 96H200C213.3 176 224 186.8 224 200S213.3 224 200 224h-160C26.75 224 16 213.3 16 200v-160C16 26.75 26.75 16 40 16z" />
    </svg>
  )
}

export const ArrowForwardIcon = (props) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.829 12.074">
      <path id="Path_1477" data-name="Path 1477" d="M6.806,12.074l-.535-.536L11.4,6.422H0V5.667H11.373L6.241.528,6.86,0l5.969,6.06Z" />
    </svg>
  )
}

export const ArrowBackIcon = (props) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.829 12.074">
      <path id="Path_1476" data-name="Path 1476" d="M6.806,0,6.271.536,11.4,5.652H0v.755H11.372L6.241,11.546l.619.528,5.969-6.06Z" transform="translate(12.829 12.074) rotate(180)" />
    </svg>
  )
}

export const TaskListIcon = (props) => {
  return (
    <svg {...props} viewBox="0 0 43 37" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Group 800">
        <g id="tasks">
          <path d="M11.7349 0.335283L13.1598 1.67641C13.3275 1.92788 13.4951 2.17934 13.4951 2.4308C13.4951 2.68226 13.3275 2.93372 13.1598 3.10136L7.12476 9.22027L5.78363 10.4776C5.61598 10.6452 5.36452 10.8129 5.02924 10.8129C4.77778 10.8129 4.52632 10.6452 4.35867 10.4776L0.335283 6.53801C0.167641 6.37037 0 6.11891 0 5.78363C0 5.53216 0.167641 5.2807 0.335283 5.11306L1.67641 3.77193C1.84405 3.60429 2.09552 3.52047 2.34698 3.52047C2.68226 3.52047 2.93372 3.60429 3.10136 3.77193L4.94542 5.61598L10.3099 0.335283C10.4776 0.167641 10.729 0 11.0643 0C11.3158 0 11.5673 0.167641 11.7349 0.335283ZM11.7349 13.6628L13.1598 15.0877C13.3275 15.2554 13.4951 15.5068 13.4951 15.8421C13.4951 16.0936 13.3275 16.345 13.1598 16.5127L5.78363 23.8889C5.61598 24.0565 5.36452 24.2242 5.02924 24.2242C4.77778 24.2242 4.52632 24.0565 4.35867 23.8889L0.335283 19.8655C0.167641 19.6979 0 19.4464 0 19.1949C0 18.9435 0.167641 18.692 0.335283 18.4405L1.67641 17.1832C1.84405 17.0156 2.09552 16.848 2.34698 16.848C2.68226 16.848 2.93372 17.0156 3.10136 17.1832L4.94542 19.0273L10.3099 13.6628C10.4776 13.4951 10.729 13.4113 11.0643 13.4113C11.3158 13.4113 11.5673 13.4951 11.7349 13.6628ZM41.6589 2.68226C42.3294 2.68226 43 3.35283 43 4.02339V6.70565C43 7.46004 42.3294 8.04678 41.6589 8.04678H17.5185C16.7641 8.04678 16.1774 7.46004 16.1774 6.70565V4.02339C16.1774 3.35283 16.7641 2.68226 17.5185 2.68226H41.6589ZM41.6589 16.0936C42.3294 16.0936 43 16.7641 43 17.4347V20.117C43 20.8713 42.3294 21.4581 41.6589 21.4581H17.5185C16.7641 21.4581 16.1774 20.8713 16.1774 20.117V17.4347C16.1774 16.7641 16.7641 16.0936 17.5185 16.0936H41.6589Z" fill="#E0257B" />
          <path d="M11.7349 0.335283L13.1598 1.67641C13.3275 1.92788 13.4951 2.17934 13.4951 2.4308C13.4951 2.68226 13.3275 2.93372 13.1598 3.10136L7.12476 9.22027L5.78363 10.4776C5.61598 10.6452 5.36452 10.8129 5.02924 10.8129C4.77778 10.8129 4.52632 10.6452 4.35867 10.4776L0.335283 6.53801C0.167641 6.37037 0 6.11891 0 5.78363C0 5.53216 0.167641 5.2807 0.335283 5.11306L1.67641 3.77193C1.84405 3.60429 2.09552 3.52047 2.34698 3.52047C2.68226 3.52047 2.93372 3.60429 3.10136 3.77193L4.94542 5.61598L10.3099 0.335283C10.4776 0.167641 10.729 0 11.0643 0C11.3158 0 11.5673 0.167641 11.7349 0.335283ZM11.7349 13.6628L13.1598 15.0877C13.3275 15.2554 13.4951 15.5068 13.4951 15.8421C13.4951 16.0936 13.3275 16.345 13.1598 16.5127L5.78363 23.8889C5.61598 24.0565 5.36452 24.2242 5.02924 24.2242C4.77778 24.2242 4.52632 24.0565 4.35867 23.8889L0.335283 19.8655C0.167641 19.6979 0 19.4464 0 19.1949C0 18.9435 0.167641 18.692 0.335283 18.4405L1.67641 17.1832C1.84405 17.0156 2.09552 16.848 2.34698 16.848C2.68226 16.848 2.93372 17.0156 3.10136 17.1832L4.94542 19.0273L10.3099 13.6628C10.4776 13.4951 10.729 13.4113 11.0643 13.4113C11.3158 13.4113 11.5673 13.4951 11.7349 13.6628ZM41.6589 2.68226C42.3294 2.68226 43 3.35283 43 4.02339V6.70565C43 7.46004 42.3294 8.04678 41.6589 8.04678H17.5185C16.7641 8.04678 16.1774 7.46004 16.1774 6.70565V4.02339C16.1774 3.35283 16.7641 2.68226 17.5185 2.68226H41.6589ZM41.6589 16.0936C42.3294 16.0936 43 16.7641 43 17.4347V20.117C43 20.8713 42.3294 21.4581 41.6589 21.4581H17.5185C16.7641 21.4581 16.1774 20.8713 16.1774 20.117V17.4347C16.1774 16.7641 16.7641 16.0936 17.5185 16.0936H41.6589Z" fill="#E0257B" />
        </g>
        <g id="tasks_2">
          <path d="M5.44852 28.1638C7.62786 28.1638 9.47191 30.0079 9.47191 32.1872C9.47191 34.4504 7.62786 36.2106 5.44852 36.2106C3.18536 36.2106 1.34131 34.4504 1.34131 32.1872C1.34131 30.0079 3.18536 28.1638 5.44852 28.1638ZM41.659 29.5049C42.3296 29.5049 43.0002 30.1755 43.0002 30.8461V33.5283C43.0002 34.2827 42.3296 34.8695 41.659 34.8695H17.5187C16.7643 34.8695 16.1776 34.2827 16.1776 33.5283V30.8461C16.1776 30.1755 16.7643 29.5049 17.5187 29.5049H41.659Z" fill="#D8D8D8" />
          <path d="M5.44852 28.1638C7.62786 28.1638 9.47191 30.0079 9.47191 32.1872C9.47191 34.4504 7.62786 36.2106 5.44852 36.2106C3.18536 36.2106 1.34131 34.4504 1.34131 32.1872C1.34131 30.0079 3.18536 28.1638 5.44852 28.1638ZM41.659 29.5049C42.3296 29.5049 43.0002 30.1755 43.0002 30.8461V33.5283C43.0002 34.2827 42.3296 34.8695 41.659 34.8695H17.5187C16.7643 34.8695 16.1776 34.2827 16.1776 33.5283V30.8461C16.1776 30.1755 16.7643 29.5049 17.5187 29.5049H41.659Z" fill="#D8D8D8" />
        </g>
      </g>
    </svg>
  )
}

export const DashboardIcon = (props) => {
  return (
    <svg {...props} viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Group 723">
        <path id="Vector" d="M27.5 54.9976C12.3353 54.9976 0 42.6623 0 27.5C0 12.3377 12.3353 0 27.5 0C42.6646 0 54.9999 12.3353 54.9999 27.5C54.9999 42.6647 42.6646 55 27.5 55V54.9976ZM27.5 5.78897C15.5289 5.78897 5.78896 15.5289 5.78896 27.5C5.78896 39.4711 15.5289 49.211 27.5 49.211C39.471 49.211 49.211 39.4711 49.211 27.5C49.211 15.5289 39.471 5.78897 27.5 5.78897Z" fill="#D8D8D8" />
        <path id="Vector_2" d="M27.5003 54.9976C18.8386 54.9976 10.8594 51.0394 5.60596 44.1409L10.213 40.6337C14.3618 46.0826 20.6621 49.2086 27.5003 49.2086C39.4714 49.2086 49.2113 39.4687 49.2113 27.4976C49.2113 15.5265 39.4714 5.78897 27.5003 5.78897V0C42.6625 0 55.0002 12.3353 55.0002 27.5C55.0002 42.6647 42.6649 55 27.5003 55V54.9976Z" fill="#E0257B" />
      </g>
    </svg>
  )
}

export const LockIcon = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 448 512">
      <path d="M384 223.1l-32 0V127.1c0-70.59-57.41-127.1-128-127.1S96 57.41 96 127.1v95.1L64 223.1c-35.35 0-64 28.65-64 64v160c0 35.35 28.65 64 64 64h320c35.35 0 64-28.65 64-64v-160C448 252.7 419.3 223.1 384 223.1zM128 128c0-52.94 43.06-96 96-96s96 43.06 96 96v96H128V128zM416 448c0 17.64-14.36 32-32 32H64c-17.64 0-32-14.36-32-32V288c0-17.64 14.36-32 32-32h320c17.64 0 32 14.36 32 32V448z" />
    </SvgIcon>
  )
}

export const EditComment = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 12 12">
      <path d="M10.5 0C11.3203 0 12 0.679688 12 1.5V8.25C12 9.09375 11.3203 9.75 10.5 9.75H7.125L4.19531 11.9531C4.125 12 4.07812 12 4.03125 12C3.86719 12 3.75 11.9062 3.75 11.7188V9.75H1.5C0.65625 9.75 0 9.09375 0 8.25V1.5C0 0.679688 0.65625 0 1.5 0H10.5ZM10.875 8.25V1.5C10.875 1.3125 10.6875 1.125 10.5 1.125H1.5C1.28906 1.125 1.125 1.3125 1.125 1.5V8.25C1.125 8.46094 1.28906 8.625 1.5 8.625H4.875V10.0312L6.44531 8.85938L6.75 8.625H10.5C10.6875 8.625 10.875 8.46094 10.875 8.25ZM3.84375 5.71875L5.85938 3.70312L6.98438 4.82812L4.96875 6.84375L3.98438 6.9375C3.84375 6.96094 3.72656 6.84375 3.75 6.70312L3.84375 5.71875ZM7.59375 2.57812L8.10938 3.09375C8.27344 3.25781 8.27344 3.53906 8.10938 3.70312L7.45312 4.35938L6.32812 3.23438L6.98438 2.57812C7.14844 2.41406 7.42969 2.41406 7.59375 2.57812Z" fill="currentColor" />
    </SvgIcon>
  )
}

export const CheckBoxIcon = (props) => {
  return (
    <SvgIcon {...props} fill="none" viewBox="0 0 20 20">
      <rect x="0.5" y="0.5" width="19" height="19" rx="3.5" stroke="currentColor" />
    </SvgIcon>
  )
}

export const CheckBoxIconChecked = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 20 20">
      <rect x="0.5" y="0.5" width="19" height="19" rx="3.5" fill="currentColor" />
      <rect x="0.5" y="0.5" width="19" height="19" rx="3.5" stroke="currentColor" />
      <path d="M13.4961 6.89062C13.5938 6.79297 13.75 6.79297 13.8281 6.89062L14.3945 7.4375C14.4727 7.53516 14.4727 7.69141 14.3945 7.76953L8.53516 13.6289C8.4375 13.7266 8.30078 13.7266 8.20312 13.6289L5.58594 11.0312C5.50781 10.9336 5.50781 10.7773 5.58594 10.6992L6.15234 10.1328C6.23047 10.0547 6.38672 10.0547 6.48438 10.1328L8.35938 12.0273L13.4961 6.89062Z" fill="white" />
    </SvgIcon>
  )
}