import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  IconButton,
  Stack,
  SvgIcon,
  FormControl,
  LinearProgress,
  Grid,
  Typography,
  Avatar,
  Tooltip,
  Badge,
  Checkbox,
  Select,
  MenuItem,
  Divider,
  InputBase,
  ListItemText,
  InputLabel,
  Pagination,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Table,
  FormControlLabel,
} from '@mui/material';
import { Box } from '@mui/system';
import { forwardRef, useContext, useEffect, useMemo, useState } from 'react';
import useAfasData from '../hooks/useAfasData';
import SwiperCard from '../components/SwiperCard';
import { useTranslation } from 'react-i18next';
import TotalAppliedIcon from '../images/TotalAppliedCandidates.svg';
import CheckIcon from '../images/Check_Icon.svg';
import OnHoldIcon from '../images/OnHoldIcon.svg';
import RejectedIcon from '../images/RejectedIcon.svg';
import CloseIcon from '@mui/icons-material/Close';
import ReviewedIcon from '../images/ReviewedIcon.svg';
import OverviewHeaderButton from '../components/OverviewHeaderButton';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import MarkEmailReadOutlinedIcon from '@mui/icons-material/MarkEmailReadOutlined';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import HailIcon from '@mui/icons-material/Hail';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import ClearIcon from '@mui/icons-material/Clear';
import styled from '@emotion/styled';
import OverviewHeaderTitle from '../components/OverviewHeaderTitle';
import ApplicationsFilter from '../components/ApplicationsFilter';
import VacancyNavigation from '../components/VacancyNavigation';
import CandidateListEntry from '../components/CandidateListEntry';
import { CheckBoxIcon, CheckBoxIconChecked } from '../components/Icons';
import { LoadingButton } from '@mui/lab';
import { TokenContext } from '../context/TokenContext';
import { afasPoster } from '../hooks/useAfasDataMutation';
import { closeSnackbar, enqueueSnackbar } from 'notistack';

export const getStatusColor = (statusCode) => {
  /**
   *  Rejected = '005',
   *  SelectionCommittee = '002',
   *  Invited = '003',
   *  OnHold = '004',
   *  Completed = '007',
   *  BeingProcessed = '002',
   *  RejectedByChairPerson = '008',
   *  Employed = '006',
   *  OnHoldEmailSent = '009',
   */
  switch (statusCode) {
    case '005':
      return '#EA0D0D';
    case '008':
      return '#EA0D0D';
    case '002':
      return '#afafaf';
    case '003':
      return '#1DC124';
    case '004':
      return '#319DD3';
    case '009':
      return '#319DD3';
    default:
      return '#888888';
  }
};

const getStatusIcon = (statusCode) => {
  switch (statusCode) {
    case '005':
      return RejectedIcon;
    case '008':
      return RejectedIcon;
    case '002':
      return ReviewedIcon;
    case '003':
      return CheckIcon;
    case '004':
      return OnHoldIcon;
    case '009':
      return OnHoldIcon;
    default:
      return ReviewedIcon;
  }
};

function CardOverviewIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 448 512">
      <path
        fill={props?.fill ? props.fill : '#ADAFBB'}
        d="M96 32H32C14.33 32 0 46.33 0 64v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V64C128 46.33 113.7 32 96 32zM256 32H192C174.3 32 160 46.33 160 64v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V64C288 46.33 273.7 32 256 32zM416 32h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V64C448 46.33 433.7 32 416 32zM96 352H32c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64C128 366.3 113.7 352 96 352zM256 352H192c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64C288 366.3 273.7 352 256 352zM416 352h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64C448 366.3 433.7 352 416 352zM96 192H32C14.33 192 0 206.3 0 224v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V224C128 206.3 113.7 192 96 192zM256 192H192C174.3 192 160 206.3 160 224v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V224C288 206.3 273.7 192 256 192zM416 192h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V224C448 206.3 433.7 192 416 192z"
      />
    </SvgIcon>
  );
}

function CardListIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path
        fill={props?.fill ? props.fill : '#ADAFBB'}
        d="M3,14h4v-4H3V14z M3,19h4v-4H3V19z M3,9h4V5H3V9z M8,14h13v-4H8V14z M8,19h13v-4H8V19z M8,5v4h13V5H8z"
      />
    </SvgIcon>
  );
}

const CardStatusAvatar = styled(Avatar)({
  position: 'absolute',
  right: '1em',
  top: 0,
  transform: 'translateY(-50%)',
  width: '48px',
  height: '48px',
  boxShadow: '0px 15px 15px rgba(0,0,0,0.06)',
  '& .MuiAvatar-img': {
    filter: 'brightness(100)',
    margin: 'auto',
    objectFit: 'contain',
  },
});

const TooltipInner = forwardRef(function TooltipInner(props, ref) {
  return (
    <span {...props} ref={ref}>
      {props?.children}
    </span>
  );
});

function BadgeEmailSent({ children, application }) {
  const { t } = useTranslation('common');
  return (
    <Badge
      overlap="circular"
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      badgeContent={
        application.StatusCode === '009' ||
          application.StatusCode === '005' ||
          application.StatusCode === '007' ||
          application.StatusCode === '006' ? (
          <Tooltip arrow title={t('cards.card.contacted')}>
            <TooltipInner>
              <Box
                sx={(theme) => ({
                  backgroundColor: getStatusColor(application.StatusCode),
                  borderRadius: '50%',
                  width: '30px',
                  height: '30px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '1.125rem',
                  transform: 'translate(90%, 40%)',
                  outlineOffset: '0px',
                  boxShadow: theme.shadows[1],
                  outlineWidth: '1px',
                  outlineStyle: 'solid',
                })}
              >
                <MarkEmailReadOutlinedIcon fontSize=".9rem" htmlColor="#FFF" />
              </Box>
            </TooltipInner>
          </Tooltip>
        ) : (
          ''
        )
      }
    >
      {children}
    </Badge>
  );
}

const StyledTableCell = styled(TableCell)({
  paddingInline: '2em !important',
  paddingBlock: '1em',
});

function VacancyOverview({ view = 'grid' }) {
  const { t, i18n } = useTranslation('common');
  const defaultVacancyFilter = t('candidates.footer.all');
  const params = useParams();
  let { state, search } = useLocation();
  const listView = view === 'list';
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState('');
  const [sortObj, setSortObj] = useState({ key: 'ApplicationID', order: 'desc' });
  const [debouncedSortObj, setDebouncedSortObj] = useState({});
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const [applications, setApplications] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loadingBulkAction, setLoadingBulkAction] = useState(false)

  useEffect(() => {
    const debounceId = setTimeout(() => {
      setDebouncedSearchTerm(searchValue);
    }, 1000);
    return () => {
      clearTimeout(debounceId);
    };
  }, [searchValue]);

  useEffect(() => {
    const debounceSortId = setTimeout(() => {
      setDebouncedSortObj(sortObj);
    }, 100);
    return () => {
      clearTimeout(debounceSortId);
    };
  }, [sortObj]);

  useEffect(() => {
    if (debouncedSearchTerm !== '') {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'searchOverview',
        search: debouncedSearchTerm,
      });
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (JSON.stringify(debouncedSortObj) !== '{"key":"ApplicationID","order":"desc"}') {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'sortOverview',
        key: JSON.stringify(debouncedSortObj?.key),
        order: JSON.stringify(debouncedSortObj?.order),
      });
    }
  }, [debouncedSortObj]);

  const vacancyId = params.VacancyID;
  const totalCandidates = state?.candidates;
  let initialState = state?.statusCode ?? 'all';

  const {
    data: applicationData = [],
    isLoading: applicationIsLoading,
    mutate: applicationMutator,
  } = useAfasData(`/vacancy/${vacancyId}/applications/list`, {
    lang: i18n.language
  });

  const {
    data: vacancyData = {},
    isLoading: vacancyIsLoading,
    mutate: infoMutator,
  } = useAfasData(`/vacancies/${vacancyId}/info`, {
    lang: i18n.language
  });
  /**
   * TotalCandidates: 32
   * TotalInvited: 9
   * TotalOnHold: 11
   * TotalRejected: 11
   * TotalReviewOpen: 1
   * VacancyID: 762
   * VacancyTitle: "Assistant Professor: Artificial Intelligence in Biomechanics and Robotics"
   * Competence1: mainVacancy.Competence1 || '',
   * Competence2: mainVacancy.Competence2 || '',
   * Competence3: mainVacancy.Competence3 || '',
   * Competence4: mainVacancy.Competence4 || '',
   * Competence5: mainVacancy.Competence5 || '',
   * Role: vacancyRole,
   */

  const competences = [];
  if (vacancyData) {
    if (vacancyData.Competence1 !== '') {
      competences.push(vacancyData.Competence1);
    }
    if (vacancyData.Competence2 !== '') {
      competences.push(vacancyData.Competence2);
    }
    if (vacancyData.Competence3 !== '') {
      competences.push(vacancyData.Competence3);
    }
    if (vacancyData.Competence4 !== '') {
      competences.push(vacancyData.Competence4);
    }
    if (vacancyData.Competence5 !== '') {
      competences.push(vacancyData.Competence5);
    }
  }

  const [statusFilter, setStatusFilter] = useState(initialState ?? 'all');

  const handleStatusFilter = (event, newStatus) => {
    setCurrentPage(1);
    if (newStatus !== null) {
      setStatusFilter(newStatus);
    }
  };

  const [filters, setFilters] = useState({
    search: {},
    sort: {},
    status: {},
    filters: []
  });

  const handleChange = (event) => {
    switch (event.target.value) {
      case 'name-asc':
        setSortObj({
          key: 'ApplicantName',
          order: 'asc',
        });
        break;
      case 'name-desc':
        setSortObj({
          key: 'ApplicantName',
          order: 'desc',
        });
        break;
      case 'competence1':
        setSortObj({
          key: 'Competence1Average',
          order: 'desc',
        });
        break;
      case 'competence2':
        setSortObj({
          key: 'Competence2Average',
          order: 'desc',
        });
        break;
      case 'competence3':
        setSortObj({
          key: 'Competence3Average',
          order: 'desc',
        });
        break;
      case 'competence4':
        setSortObj({
          key: 'Competence4Average',
          order: 'desc',
        });
        break;
      case 'competence5':
        setSortObj({
          key: 'Competence5Average',
          order: 'desc',
        });
        break;
      case 'oldest':
        setSortObj({
          key: 'ApplicationID',
          order: 'asc',
        });
        break;
      case 'latest':
        setSortObj({
          key: 'ApplicationID',
          order: 'desc',
        });
        break;
      case 'rating-asc':
        setSortObj({
          key: [
            (item) => (typeof item.SuggestInvite === 'string' ? Infinity : item.SuggestInvite),
            (item) => (typeof item.SuggestOnHold === 'string' ? Infinity : item.SuggestOnHold),
            (item) => (typeof item.SuggestReject === 'string' ? Infinity : item.SuggestReject),
          ],
          order: ['asc', 'asc', 'asc'],
        });
        break;
      case 'rating-desc': // Hoogste naar laagste beoordeling
        setSortObj({
          key: [
            (item) => (typeof item.SuggestInvite === 'string' ? -Infinity : item.SuggestInvite),
            (item) => (typeof item.SuggestOnHold === 'string' ? -Infinity : item.SuggestOnHold),
            (item) => (typeof item.SuggestReject === 'string' ? -Infinity : item.SuggestReject),
          ],
          order: ['desc', 'desc', 'desc'],
        });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      search: {
        value: searchValue,
      }
    }));
    setFilters((prev) => {
      prev.status = {
        field: 'StatusCode',
        operator: {
          "operator": "contains",
          "method": (a, b) => {
            if (a === null) return false;
            if (typeof a === 'string' || a instanceof String) {
              a = a.toLowerCase();
              b = b.toLowerCase();
            }
            return a.includes(b);
          }
        },
        value: statusFilter === 'all' ? '' : statusFilter
      };
      return prev;
    });
    setFilters((prev) => {
      prev.sort = {
        field: Array.isArray(sortObj.key) ? sortObj.key : [sortObj.key],
        order: Array.isArray(sortObj.order) ? sortObj.order : [sortObj.order]
      };
      return prev;
    })
  }, [applicationData, statusFilter, sortObj, searchValue]);

  useEffect(() => {
    applicationData.map((application) => {
      if (application.Rating === '-' || application.Rating === null) application.Rating = 0;
      if (application.Competence1Average === null) application.Competence1Average = -1;
      if (application.Competence2Average === null) application.Competence2Average = -1;
      if (application.Competence3Average === null) application.Competence3Average = -1;
      if (application.Competence4Average === null) application.Competence4Average = -1;
      if (application.Competence5Average === null) application.Competence5Average = -1;
      return application;
    });
  }, [applicationData]);

  const handleCheckboxChange = (id) => {
    setSelectedRows(
      (prevSelected) =>
        prevSelected.includes(id)
          ? prevSelected.filter((rowId) => rowId !== id) // Unselect if already selected
          : [...prevSelected, id] // Add to selected
    );
  };

  const handleSelectAll = (event) => {
    setSelectedRows(event.target.checked ? applications.map((c) => c.ApplicationID) : []);
  };

  useEffect(() => {
    setSelectedRows([]);
  }, [applications]);

  const byPage = (candidates) => {
    const startIndex = (currentPage - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    return candidates.slice(startIndex, endIndex);
  };

  const paginatedCandidates = useMemo(
    () => byPage(applications),
    [applications, currentPage, rowsPerPage, byPage]
  );
  const handleRowsChange = (event) => setRowsPerPage(event.target.value);
  const handlePageChange = (event, newPage) => setCurrentPage(newPage);


  const action = (snackbarId) => (
    <>
      <IconButton
        onClick={() => {
          closeSnackbar(snackbarId);
        }}
      >
        <ClearIcon />
      </IconButton>
    </>
  );
  const token = useContext(TokenContext);
  async function updateApplicationStatus(reaction, applicationId) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'changeApplicationStatus',
      status: reaction.action,
    });
    const path = `/vacancies/${vacancyId}/applications/${applicationId}/actions`;
    const body = reaction;
    const arg = { token, body };
    let postData = await afasPoster(path, { arg });
    console.log({ postData });
  }

  async function rejectAll() {
    const idstoReject = selectedRows;
    console.log(idstoReject);
    try {
      for (let i = 0; i < idstoReject.length; i++) {
        await updateApplicationStatus({ action: 'reject' }, idstoReject[i]);
      }
      enqueueSnackbar(t('cards.card.workflow.reject'), {
        action,
        variant: 'success',
      });
      console.log('Successfully changed application status.');
      setLoadingBulkAction(false);
    } catch (error) {
      enqueueSnackbar(t('cards.card.workflow.failed'), {
        action,
        variant: 'error',
      });
      console.error('Failed to add the new item.');
      setLoadingBulkAction(false);
    }
    // For every idstoReject, call the function changeApplicationStatus with status 'reject'
    infoMutator();
    applicationMutator();
  }

  // opsplitsen naar 3 acties?
  const handleActionClick = (action) => {
    setLoadingBulkAction(action);
    console.log('Performing action on selected rows:', selectedRows);
    switch (action) {
      case 'change-vacancy':
        alert('Functionality not available');
        setLoadingBulkAction(false);
        break;
      case 'reject':
        rejectAll();
        break;
      case 'talentpool':
        alert('Talentpool not available');
        setLoadingBulkAction(false);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Box mb={4} mt={2}>
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          justifyContent="space-between"
          alignItems="start"
          spacing={4}
        >
          <OverviewHeaderTitle vacancyData={vacancyData} vacancyIsLoading={vacancyIsLoading} />
          <Stack
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              alignItems: 'flex-start',
              gap: 0,
            }}
          >
            <OverviewHeaderButton
              parseValue="all"
              color="#4e1370"
              selected={statusFilter}
              onChange={handleStatusFilter}
              text={t('cards.header.applied')}
              number={vacancyData.TotalCandidates || totalCandidates}
              icon={TotalAppliedIcon}
              width="32"
              height="32"
              classAddOn={'total'}
            />
            <OverviewHeaderButton
              parseValue="003"
              color="#1DC124"
              selected={statusFilter}
              onChange={handleStatusFilter}
              text={t('cards.header.invited')}
              number={vacancyData.TotalInvited || '0'}
              icon={CheckIcon}
              width="32"
              height="32"
              classAddOn={'invited'}
            />
            <OverviewHeaderButton
              parseValue="004,009"
              color="#319DD3"
              selected={statusFilter}
              onChange={handleStatusFilter}
              text={t('cards.header.onhold')}
              number={vacancyData.TotalOnHold || '0'}
              icon={OnHoldIcon}
              width="32"
              height="32"
              classAddOn={'onhold'}
            />
            <OverviewHeaderButton
              parseValue="005,008"
              color="#EA0D0D"
              selected={statusFilter}
              onChange={handleStatusFilter}
              text={t('cards.header.rejected')}
              number={vacancyData.TotalRejected || '0'}
              icon={RejectedIcon}
              width="32"
              height="32"
              classAddOn={'rejected'}
            />
            <OverviewHeaderButton
              parseValue="002"
              color="#afafaf"
              selected={statusFilter}
              onChange={handleStatusFilter}
              text={t('cards.header.reviewed')}
              number={vacancyData.TotalReviewOpen || '0'}
              icon={ReviewedIcon}
              width="32"
              height="32"
              classAddOn={'reviewed'}
            />
          </Stack>
        </Stack>
      </Box>

      <VacancyNavigation active={'overview'}>
        <Box
          sx={(theme) => ({
            display: 'inline-block',
            boxShadow: theme.shadows[1],
            paddingTop: '2.5px',
            paddingBottom: '2.5px',
          })}
          className="bordered"
        >
          <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
            <SearchIcon />
          </IconButton>
          <InputBase
            sx={{
              flex: 1,
              pl: 1,
              fontSize: '16px',
              letterSpacing: 'normal',
            }}
            className="bordered"
            placeholder={t('vacancies.overview.search')}
            inputProps={{ 'aria-label': 'Search by name' }}
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </Box>
      </VacancyNavigation>

      <Box my={4}>
        <Divider />
      </Box>
      <Stack direction="row" justifyContent="space-between" alignItems="flex-start" spacing={2} useFlexGap>
        <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2} useFlexGap>
          <Box ml={1}>
            <FormControlLabel
              control={
                <Checkbox
                  icon={<CheckBoxIcon sx={{
                    fill: 'none',
                    width: '20px',
                    height: '20px',
                  }} />}
                  checkedIcon={<CheckBoxIconChecked sx={{
                    width: '20px',
                    height: '20px',
                  }} />}
                  color="secondary"
                  onChange={handleSelectAll}
                  checked={selectedRows.length > 0 && selectedRows.length === applications.length}
                />}
              label={t('vacancies.overview.selectall')}
            />
          </Box>
          {selectedRows.length > 0 ? (
            <Typography variant="body1" fontWeight={700}>
              {`${selectedRows.length} `}
              {
                selectedRows.length === 1 ?
                  t('vacancies.overview.rowselected') :
                  t('vacancies.overview.rowsselected')
              }
            </Typography>
          ) : (
            <Tooltip title={t('vacancies.overview.selectallinstructions')}>
              <IconButton
                sx={(theme) => ({
                  boxShadow: theme.shadows[1]
                })}
                variant="contained"
                color="primary"
              >
                <InfoOutlinedIcon />
              </IconButton>
            </Tooltip>
          )}
        </Stack>
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={2}
          useFlexGap
          sx={{
            marginLeft: 'auto !important'
          }}>
          <Box
            sx={{
              display: 'inline-block',
            }}
          >
            <FormControl
              variant='standard'
              sx={(theme) => ({
                minWidth: '200px',
                maxWidth: '250px',
                boxShadow: theme.shadows[1],
              })}
              className='bordered'
            >
              <Select
                onChange={handleChange}
                defaultValue={`latest`}
                className='bordered'
                sx={{
                  paddingLeft: '16px',
                  paddingRight: '16px',
                  height: '44px',
                  backgroundColor: '#FFF',
                }}
              >
                <MenuItem value={`latest`}>{t('vacancies.overview.sort.newtoold')}</MenuItem>
                <MenuItem value={`oldest`}>{t('vacancies.overview.sort.oldtonew')}</MenuItem>
                <MenuItem value={`name-asc`}>{t('vacancies.overview.sort.alphaasc')}</MenuItem>
                <MenuItem value={`name-desc`}>{t('vacancies.overview.sort.alphadesc')}</MenuItem>
                <MenuItem value={`rating-desc`}>{t('vacancies.overview.sort.hightolow')}</MenuItem>
                <MenuItem value={`rating-asc`}>{t('vacancies.overview.sort.lowtohigh')}</MenuItem>
                {vacancyData.Competence1 && (
                  <MenuItem value={`competence1`}>
                    {vacancyData.Competence1} {t('vacancies.overview.sort.desc')}
                  </MenuItem>
                )}
                {vacancyData.Competence2 && (
                  <MenuItem value={`competence2`}>
                    {vacancyData.Competence2} {t('vacancies.overview.sort.desc')}
                  </MenuItem>
                )}
                {vacancyData.Competence3 && (
                  <MenuItem value={`competence3`}>
                    {vacancyData.Competence3} {t('vacancies.overview.sort.desc')}
                  </MenuItem>
                )}
                {vacancyData.Competence4 && (
                  <MenuItem value={`competence4`}>
                    {vacancyData.Competence4} {t('vacancies.overview.sort.desc')}
                  </MenuItem>
                )}
                {vacancyData.Competence5 && (
                  <MenuItem value={`competence5`}>
                    {vacancyData.Competence5} {t('vacancies.overview.sort.desc')}
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </Box>
          <ApplicationsFilter applications={applications} setFilters={setFilters} filters={filters} applicationData={applicationData} setApplications={setApplications} vacancyData={vacancyData} />
          <IconButton className="ev_view-vacancy-applications-block"
            onClick={() => {
              navigate(
                {
                  pathname: `/vacancies/${vacancyId}/applications/overview/`,
                  search: search,
                },
                {
                  state: {
                    statusCode: 'all',
                  },
                }
              );
            }}
            sx={(theme) => ({
              backgroundColor: !listView ? theme.palette.grey.A100 : 'transparent',
            })}
          >
            <CardOverviewIcon width="30" height="30" />
          </IconButton>
          <IconButton className="ev_view-vacancy-applications-list"
            onClick={() => {
              navigate(
                {
                  pathname: `/vacancies/${vacancyId}/applications/listoverview/`,
                  search: search,
                },
                {
                  state: {
                    statusCode: 'all',
                  },
                }
              );
            }}
            sx={(theme) => ({
              backgroundColor: listView ? theme.palette.grey.A100 : 'transparent',
            })}
          >
            <CardListIcon width="30" height="34" />
          </IconButton>
        </Stack>
      </Stack >

      <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
        {selectedRows.length > 0 && (
          <>
            <Box>
              <LoadingButton
                variant="outlined"
                disabled={loadingBulkAction === 'change-vacancy'}
                loading={loadingBulkAction === 'change-vacancy'}
                loadingPosition="end"
                endIcon={<CompareArrowsIcon />}
                onClick={() => handleActionClick('change-vacancy')}
              >
                {t('vacancies.overview.changevacancy')}
              </LoadingButton>
            </Box>
            {!vacancyIsLoading && vacancyData?.Role === 'chair_person' && (
              <Box>
                <LoadingButton
                  variant="outlined"
                  disabled={loadingBulkAction === 'reject'}
                  loading={loadingBulkAction === 'reject'}
                  loadingPosition="end"
                  endIcon={<CloseIcon />}
                  onClick={() => handleActionClick('reject')}
                >
                  {t('vacancies.overview.reject')}
                </LoadingButton>
              </Box>
            )}
            <Box>
              <LoadingButton
                variant="outlined"
                disabled={loadingBulkAction === 'talentpool'}
                loading={loadingBulkAction === 'talentpool'}
                loadingPosition="end"
                endIcon={<HailIcon />}
                onClick={() => handleActionClick('talentpool')}
              >
                {t('vacancies.overview.rejecttalentpool')}
              </LoadingButton>
            </Box>
          </>
        )}
      </Stack>
      {
        (filters.filters.length > 0 || filters.search.value) && (
          <Box>{applications?.length} {applications.length === 1 ? t('vacancies.overview.candidate') : t('vacancies.overview.candidates')}</Box>
        )
      }
      <Box pb={5} mt={5}>
        {applicationIsLoading && <LinearProgress mt={2} />}
        {applications?.length === 0 && !applicationIsLoading && (
          <Typography variant="h4" color="initial" align="center">
            {t('cards.card.noresults')}
          </Typography>
        )}
        {listView && (
          <>
            <Table sx={{ borderSpacing: '0 0.75em !important', borderCollapse: 'separate !important' }}>
              <TableHead>
                <TableRow style={{ background: 'white' }}>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell>
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                      {t('candidates.header.name')}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                      {t('candidates.header.rating')}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                      {t('candidates.header.status')}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                      {t('candidates.header.averagescore')}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                      {t('candidates.header.applicantstam')}
                    </Typography>
                  </StyledTableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {paginatedCandidates.length > 0 &&
                  paginatedCandidates.map((candidate) => {
                    return (
                      <CandidateListEntry
                        key={candidate.SubjectID}
                        view="vacancy-list"
                        candidate={candidate}
                        handleCheckboxChange={handleCheckboxChange}
                        selectedRows={selectedRows}
                        vacancy={vacancyData}
                      />
                    );
                  })}
              </TableBody>
            </Table>
            <Box>
              <Stack
                direction="row"
                alignItems="center"
                spacing={3}
                justifyContent="flex-end"
                sx={{ px: '2em', mt: 2, mb: 3 }}
              >
                <Pagination
                  count={applications <= 0 ? 0 : Math.ceil(applications.length / rowsPerPage)}
                  page={currentPage}
                  onChange={handlePageChange}
                />
                <FormControl
                  size="small"
                  variant="outlined"
                >
                  <InputLabel sx={{ background: 'white' }} id="demo-simple-select-standard-label">{t('candidates.footer.rowsperpage')}</InputLabel>
                  <Select
                    MenuProps={{ autoFocus: false, disableAutoFocus: true }}
                    labelId="rows-select-label"
                    id="rows-select"
                    value={rowsPerPage}
                    onChange={handleRowsChange}
                    label={'Rows'}
                    className="filter-select"
                    IconComponent={KeyboardArrowDownRoundedIcon}
                    fullWidth
                    sx={{
                      minWidth: '200px',
                    }}
                  >
                    {[applications.length, 5, 10, 25, 50, 100].map((amountOfRows, i) => {
                      return i === 0 && amountOfRows <= 100 ? (
                        <MenuItem key={i} value={amountOfRows}>
                          <ListItemText primary={amountOfRows} secondary={`(${defaultVacancyFilter})`} />
                        </MenuItem>
                      ) : (
                        amountOfRows < applications.length && (
                          <MenuItem key={i} value={amountOfRows}>
                            <ListItemText primary={amountOfRows} />
                          </MenuItem>
                        )
                      );
                    })}
                  </Select>
                </FormControl>
              </Stack>
            </Box>
          </>
        )}
        {!listView && (
          <>
            {applications && vacancyData && !applicationIsLoading && (
              <Grid
                container
                spacing={6}
                alignItems="stretch"
                justifyContent="flex-start"
                direction="row"
                className="overview-grid"
                mt={2}
              >
                {applications.map((application) => {
                  return (
                    <Grid item xs={12} md={4} lg={3} mb={3} key={application.ApplicationID}>
                      <SwiperCard
                        application={application}
                        role={vacancyData.Role}
                        view="overview"
                        competences={competences}
                        applicationMutator={applicationMutator}
                        infoMutator={infoMutator}
                        handleCheckboxChange={handleCheckboxChange}
                        selectedRows={selectedRows}
                      >
                        {application.ReviewedByUser && application.StatusCode === '002' ? (
                          <></>
                        ) : (
                          <>
                            {application.StatusCode === '007' && (
                              <CardStatusAvatar
                                sx={{
                                  bgcolor: getStatusColor(application.StatusCode),
                                }}
                              >
                                <EventAvailableIcon />
                              </CardStatusAvatar>
                            )}
                            {application.StatusCode !== '007' && (
                              <BadgeEmailSent application={application}>
                                <CardStatusAvatar
                                  sx={{
                                    bgcolor: getStatusColor(application.StatusCode),
                                    '& .MuiAvatar-img': {
                                      width: '32px',
                                      lineHeightStep: '32px',
                                    },
                                  }}
                                  alt="Icon"
                                  src={getStatusIcon(application.StatusCode)}
                                />
                              </BadgeEmailSent>
                            )}
                          </>
                        )}
                      </SwiperCard>
                    </Grid>
                  );
                })}
              </Grid>
            )}
          </>
        )}
      </Box>
      <Outlet context={[applications]} />
    </>
  );
}

export default VacancyOverview;
